
$PREFIX = 'pr-'

$media-size-xs = 0px 
$media-size-sm = 567px 
$media-size-md = 768px 
$media-size-lg = 992px 
$media-size-xl = 1200px
$media-size-fhd = 1440px

$media-group = {
  xs: 0px,
  sm: 567px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
}




cssvar-definition()
  /:root
    { block }
  { block }


// $media-query-xs = "(min-width: {$media-size-xs})"
// $media-query-sm = "(min-width: {$media-size-sm})"
// $media-query-md = "(min-width: {$media-size-md})"
// $media-query-lg = "(min-width: {$media-size-lg})"
// $media-query-xl = "(min-width: {$media-size-xl})"
// $media-query-max-xs = "(max-width: {$media-size-xs})"
// $media-query-max-sm = "(max-width: {$media-size-sm})"
// $media-query-max-md = "(max-width: {$media-size-md})"
// $media-query-max-lg = "(max-width: {$media-size-lg})"
// $media-query-max-xl = "(max-width: {$media-size-xl})"

mq-xs()
  @media (min-width $media-size-xs)
    { block }

mq-sm()
  @media (min-width $media-size-sm)
    { block }

mq-md()
  @media (min-width $media-size-md)
    { block }

mq-lg()
  @media (min-width $media-size-lg)
    { block }

mq-xl()
  @media (min-width $media-size-xl)
    { block }

mq-fhd()
  @media (min-width $media-size-fhd)
    { block }

mq-till-xs()
  @media (max-width $media-size-xs)
    { block }

mq-till-sm()
  @media (max-width $media-size-sm)
    { block }

mq-till-md()
  @media (max-width $media-size-md)
    { block }

mq-till-lg()
  @media (max-width $media-size-lg)
    { block }

mq-till-xl()
  @media (max-width $media-size-xl)
    { block }

mq-till-fhd()
  @media (max-width $media-size-fhd)
    { block }

adaptive-block($separator = '-')
  { block }

  &{$separator}xs
    +mq-xs()
      { block }

  &{$separator}sm
    +mq-sm()
      { block }

  &{$separator}md
    +mq-md()
      { block }

  &{$separator}lg
    +mq-lg()
      { block }

  &{$separator}xl
    +mq-xl()
      { block }
  
  &{$separator}fhd
    +mq-fhd()
      { block }

adaptive-block-till($separator = '-till-')
  { block }

  &{$separator}xs
    +mq-till-xs()
      { block }

  &{$separator}sm
    +mq-till-sm()
      { block }

  &{$separator}md
    +mq-till-md()
      { block }

  &{$separator}lg
    +mq-till-lg()
      { block }

  &{$separator}xl
    +mq-till-xl()
      { block }
  &{$separator}fhd
    +mq-till-xl()
      { block }

both-adaptive-block()
  +adaptive-block()
    { block }

  +adaptive-block-till()
    { block }

$space-6xs = 0.15rem 
$space-5xs = 0.25rem 
$space-4xs = 7px // ~ 0.45rem
$space-3xs = 0.5rem 
$space-2xs = 10px // ~ 0.65rem
$space-xs = 0.75rem 
$space-sm = 0.85rem 
$space-md = 1rem 
$space-lg = 1.5rem 
$space-xl = 2rem 
$space-2xl = 3rem 
$space-3xl = 5rem 
$space-4xl = 8rem 
$space-5xl = 10rem 

$space-group = {
  '6xs': $space-6xs,
  '5xs': $space-5xs,
  '4xs': $space-4xs,
  '3xs': $space-3xs,
  '2xs': $space-2xs,
  'xs': $space-xs,
  'sm': $space-sm,
  'md': $space-md,
  'lg': $space-lg,
  'xl': $space-xl,
  '2xl': $space-2xl,
  '3xl': $space-3xl,
  '4xl': $space-4xl,
  '5xl': $space-5xl,
}
