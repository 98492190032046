.post-detail 
  max-w 700px
  ma 0 auto
  &__image
    pos relative
    mt 24px
  &__img 
    br-radius 12px
    
    w 100%
  &__date 
    font-family: 'Open Sans';
    font-size: 11px;
    line-height: 21px;
    color: #F9F9F9;    
  &__title
    font-family: RotondaC;
    font-size: 16px;
    line-height: 18px;
    color: $c.red
    pt 12px
    pb 10px
    +media(from('xl'))
      fs: 24px
      pb 40px
      lh: 28px
  p 
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 24px;
    color: rgba(#2B0000, 0.7)
    pb 20px
    +media(from('xl'))
      pb 40px
  .line-v
    background: #EE1B23;
    border-radius: 3px;  
    w 100% 
    h 2px 
    d block 
    ma 0px 0 16px
    +media(from('xl'))
      mb 40px
  dt 
    font-family: SansRoundedC;
    font-size: 20px
    line-height: 24px
    color: #F9F9F9;  
    br-left 4px solid #F9F9F9
    br-radius 2px
    ma 0 0 16px
    pl 16px 
    +media(from('xl'))
      font-size: 24px;
      mb 40px
      line-height: 28px;
  h2 
    // font-family: SansRoundedC;
    // font-size: 28px;
    // line-height: 32px;
    // color: #EE1B23;  
    // mb 16px
    // +media(from('xl'))
    //   fs 36px 
    //   lh 49px
    //   mb 40px

    @extends .post-detail dt
    fw: 500

  img 
    w 100% 
    d block 
    br-radius 12px 
  .columns  
    +media(from('xl'))

      d flex 
      jc space-between 
    &__head 
      d flex 
      ai center 
      jc flex-start 
      mb 20px
    &__list-title 
      color: $c.red 
      font-family: SansRoundedC;
      font-size: 20px;
      line-height: 28px;
      pb 0 !important
    img 
      d block 
      mr 25px 
      w auto !important
    &__column
      +media(from('xl'))    
        w 49%
  table 
    br-radius 12px 
    table-layout fixed
    overflow hidden
    w 100%
    mb 16px
    +media(from('xl'))
      mb 40px
    // border-collapse  
    & th 
      font-family: 'Open Sans';
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      pa 16px 
      color: #F9F9F9;
      bg: $c.orange
    & tr 
      br-bottom: 2px solid #FBB543
    & td 
      font-family: Open Sans;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      color: #EE7325;  
      bg #FECC6C
      pa 16px 

  ol 
    mt 10px
    counter-reset: li
  
    li 
      fs 13px 
      fw 500
      lh 25px
      pt 16px
      d block
      counter-increment: li
      font-family: Open Sans;
      font-size: 16px;
      line-height: 25px;
      color: rgba(#2B0000, 0.6)
      +media(from('xl'))
        mb 20px
      &:before 
        d inline-block
        content  counter(li)"."!important
        font-family: SansRoundedC;
        font-size: 24px;
        line-height: 28px;
        color: #EE1B23;
        opacity 1
        pr 5px

.article-card 
  background: #F9F9F9;
  box-shadow: 0px 4px 30px rgba(238, 115, 37, 0.16);
  border-radius: 12px;
  overflow: hidden
  margin-top 10px
  pa 20px
  &__
    &question
      position relative
      bg-color: #F9F9F9;
      transition background-color .3s ease
      &:hover
        cursor pointer
    &description
      overflow hidden
      transition: max-height .8s ease
      bg-color:  #F9F9F9;
      position relative
    &text
      font-family: SansRoundedC;
      font-style: normal;
      font-weight: normal;
      color: #EE1B23;
      fs: 20px
      lh: 1.5
      margin-bottom 10px
      +mq-xl()
        line-height: 30px;
        font-size: 22px;
    &content
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #2B0000;
      opacity: 0.7;
      margin-bottom: 20px;
    &footer
      margin: 5px 35px;
      text-align: right;
    &read
      color: #2B0000;
      font-weight: bold;

  +media(from('xl'))
    mt 25px
    f-dir row
    ai center 
    pa 20px 35px
  &__title 
    fd none 
    ff RotondaC
    fs 16px
    lh 18px
    fw 700
    mt 12px
    color #EE1B23
    +media(from('xl'))
      fs 22px 
      lh 24px
      tr 0.5s
      &:hover 
        color: $c.orange 
        tr 0.5s
  &__desc
    ff: 'Open Sans'
    fw: bold
    fs: 14px
    lh: 24px
    mt 6px
    color: #2B0000
    opacity: 0.7
