.foil
    background-repeat no-repeat
    background-size cover
    background-position top center
.img
    text-align center
.img img
    max-width 100%
.center
    display flex
    align-items center
    justify-content center
    display -webkit-flex
    -webkit-align-items center
    -webkit-justify-content center
.calendar
    display block
    width 100%
    padding 60px 60px 0

    &--range
        padding-top 0 !important

    &__gif
        w 100%
        mb 3rem

    &__top
        display block
        width 100%
        text-align center
    &__link
        display inline-block
        font-size 16px
        color #fff
        padding 10px 20px 7px 20px
        bg-color o
        border-bottom 5px solid #E65A05
        border-radius 10px
        margin-right 15px
        font-family SansRoundedC
        margin-bottom 27px
        transition all 0.1s linear
        &:last-child
            margin-right 0
        &:hover
            bg-color hov
            border-bottom 5px solid bor-hov
            cursor pointer
    &__part
        width 100%
        margin-bottom 20px
    &__left
        width 85%
        float left
        position relative
    &__arrow
        display block
        width 31px
        height 31px
        border-radius 50%
        background-color bg
        position absolute
        top -14px
        &--prev
            left 20px
        &--next
            right -35px
        &:before
            color o2
            font-size 12px
            position absolute
            top 50%
            left 50%
            transform translateX(-50%) translateY(-50%)
    &__range
        width 85%
        padding-left 60px
        mb 35px

    &__help
        display none
        width 190px
        position absolute
        left 50%
        transform translateX(-50%)
        bottom 41px
        padding 8px 4px
        text-align center
        bg-color bg
        font-family PTSans-regular, sans-serif
        color o
        font-size 15px
        border-radius 9px
        box-shadow 0 0 7px 1px rgba(0,0,0,0.1)
        transition opacity 0.15s linear
        &:before,
        &:after
            content ''
            position absolute
            left 50%
            transform translateX(-50%)
            bottom -15px
            border 8px solid transparent
            border-top 7px solid bg
        &:after
            bottom -13px
    &__arrow:hover &__help
        display block
    &__arrow.is-error:hover
        bg-color error
    &__arrow.is-error:hover:before
        color #fff
    &__arrow.is-error &__help
        bg-color error
        color #fff
    &__arrow.is-error &__help:before,
    &__arrow.is-error &__help:after
        border-top 7px solid error
    &__left-part
        padding-top 20px
    &__right
        width 12%
        float right
        padding-top 30px
    &__add
        display block
        width 78px
        float left
    &__block-measure
        width 100%
        height 69px
    &__half
        width calc(100% -78px)
        float right
    &__unit-wrap
        w 100%
        position relative

        &.is-hoverable.is-active
            z-index 7
            &:before
                content ''
                position absolute
                left -6px
                right -6px
                top -6px
                bottom -6px
                background-color o
                border-radius 16px
                z-index 6
        &.is-hoverable.is-product:hover
            &:before
                bottom -30px

        @media (min-width: 1200px)
            &.is-group
                ^[0]__picture
                    mb 10px
    &__unit
        display flex
        flex-wrap: wrap
        align-items center
        justify-content center
        display -webkit-flex
        -webkit-flex-wrap wrap
        -webkit-align-items center
        -webkit-justify-content center
        width 100%
        height 69px
        position relative
        border-bottom 1px solid o
        border-right 1px solid o
        padding 2px
        color #fff
        font-size 12px
        position relative
        z-index 3
        background-color #FBE2A9
        &--pink
            bg-color pink
        &--rose
            bg-color rose
        &--lime
            bg-color lim
        &--brown
            bg-color brow
        &--yellow
            bg-color yel
        &--orange
            bg-color oran
    &__unit-wrap.is-hoverable.is-active &__unit
        z-index 7
        border-radius 10px
    &__unit-wrap:first-child &__unit
        border-top 1px solid o
    &__row:first-child &__unit
        border-left 1px solid o
    &__picture
        text-align center
        width 85%
        height 75%
        overflow hidden
    &__picture img
        max-width 100%
        max-height 100%
    &__icon
        display block
        width 20px
        height 20px
        @extend .foil
        position absolute
        top 4px
        right 4px
        &--1
            background-image url(./../images/icon3.png)
        &--3
            background-image url(./../images/icon1.png)
        &--2
            background-image url(./../images/icon2.png)
    &__add
        width 78px
        float left
    &__block-measure
        display flex
        align-items center
    &__measure
        display inline-block
        width 38px
        height 50px
        background-repeat no-repeat
        background-position left center
        padding-top 20px
        font-size 16px
        color t
        vertical-align middle
        padding-left 1px
        &--foil1
            background-image url(./../images/pic1.png)
            padding-left 4px
        &--foil2
            background-image url(./../images/pic2.png)
    &__plus
        display inline-block
        @extend .foil
        width 19px
        height 19px
        background-image url(./../images/pic3.png)
        vertical-align middle
        margin-top 15px
    &__row
        width (100/7)%
        float left
        position relative

        &:first-child
            .zoom
                right auto
                left 0px

                &__
                    &arrow
                        &:before,
                        &:after
                            right auto
                            left 20px
    &__row.is-active
        border-radius 16px
        z-index 5
    &__row.is-active:before
        content ''
        background-color o
        position absolute
        top -26px
        left -6px
        bottom -6px
        right -5px
        border-radius 16px
        z-index 2
    &__row.is-active:after
        content ''
        width 6px
        background-color o
        position absolute
        top -0px
        bottom 0px
        right -5px
        z-index 4
        border-bottom-right-radius 20px
    &__row.is-active &__date
        color #fff
        z-index 3
    &__row.is-active &__unit-wrap:first-child &__unit
        border-top-left-radius 15px
        border-top-right-radius 15px
    &__row.is-active &__unit-wrap:last-child &__unit
        border-bottom-left-radius 12px
        border-bottom-right-radius 12px

    &__row.is-history:after
        content ''
        d block
        background-color rgba(251, 226, 169, .6)
        position absolute
        top 0
        left 0
        bottom 0
        right 0
        // h 100%
        // w 100%
        border-radius 0
        z-index 3
    &__row.is-history:first-child:after
        border-top-left-radius 13px
        border-bottom-left-radius 13px
    &__row.is-history:last-child:after
        border-top-right-radius 13px
        border-bottom-right-radius 13px
    &__food
        line-height 14px
        height 14px
        overflow hidden
        pos absolute
        w 100%
        left 0
        bottom 1px
        ta center
    &__date
        width 100%
        position absolute
        top -22px
        left 0
        text-align center
        color o2
        font-size 16px
        font-family SansRoundedC
        cursor pointer
    &__row:first-child &__cellule &__unit-wrap:first-child &__unit
        border-top-left-radius 12px
    &__row:first-child &__cellule &__unit-wrap:last-child &__unit
        border-bottom-left-radius 12px
    &__row:nth-child(7) &__cellule &__unit-wrap:first-child &__unit
        border-top-right-radius 12px
    &__row:last-child &__cellule &__unit-wrap:last-child &__unit
        border-bottom-right-radius 12px
    &__hover
        display none
    &__icons
        width 100%
        text-align center
        position absolute
        left 0
        bottom -27px
        z-index 6
        &--type
            display inline-block
            width 20px
            vertical-align middle
            margin 0 5px
            &.is-current
                opacity 0.5
                cursor default
    &__hover-half
        display none
        width 100%
        position absolute
        bottom 100%
        padding-bottom 10px
        left 0

        @media(max-width 961px)
            w 160%
            left -30%
        &.is-active
            d block

    &__hover-top
        display block
        position relative
        width 100%
        left 0
        bg-color bg
        border-radius 12px
        box-shadow 0 0 7px 1px rgba(0,0,0,0.1)
        padding 14px
        &:before,
        &:after
            content ''
            position absolute
            left 50%
            transform translateX(-50%)
            bottom -15px
            border 8px solid transparent
            border-top 7px solid bg
        &:after
            bottom -13px


    &__unit-wrap.is-hoverable:hover &__hover
        display block
    // &__row:first-child &__date:after
    //     content url(./../images/arrow5.png)
    &__row.is-active &__date:after
        content url(./../images/arrow5-hov.png)
        display inline-block
        vertical-align middle
        margin-top -4px
        padding-left 4px

.calendar__unit .category__nav--prev
    left 1px
.calendar__unit .category__nav--next
    right 1px


.category
    dispplay block
    width 100%
    &__block
        width 100%
        margin-bottom 10px
    &__name
        font-size 16px
        font-family SansRoundedC
        margin-bottom 4px
        &--liked
            color #F0730D
        &--liked:before
            content url(./../images/arrow1.png)
        &--disliked
            color #9D6846
        &--disliked:before
            content url(./../images/arrow2.png)
        &--allergic
            color o2
        &--allergic:before
            content url(./../images/arrow3.png)
        &:before
            display inline-block
            vertical-align middle
            padding-right 2px
    &__wrap-slider
        display block
        width 100%
        height 75px
        border-radius 8px
        bg-color bg
        position relative
        padding 3px
        position relative
        z-index 4
    &__slide
        width 100%
        height 100%
    &__nav
        display block
        position absolute
        top 50%
        transform translateY(-50%)
        color o
        width 12px
        height 12px
        border-radius 50%
        &--prev
            left -17px
        &--next
            right -17px
        &:before
            font-size 10px
            position absolute
            top 50%
            left 50%
            right auto
            transform translateX(-50%) translateY(-50%)
        &:hover:before
            cursor pointer
            color #fff
            border-radius 50%
        &:hover
            bg-color o
            cursor pointer

    &__picture
        text-align center
    &__picture img
        max-height 52px
    &__slider
        height 100%

        & .slides__list,
        & .swiper-wrapper
            height 100%

        & .slides__list
            d flex
            align-items center
            justify-content center

    &__close
        display block
        width 23px
        height 23px
        position absolute
        top 0
        right 0
        color o
        z-index 5
        &:before
            font-size 13px
            position absolute
            left 50%
            right auto
            top 50%
            transform translateY(-50%) translateX(-50%)
        &:hover
            background-color o
            color #fff
            cursor pointer
            border-radius 50%
.slider-wrapper
    pos relative

    &__child
        display block
        width 235px
        position absolute
        left 50% !important
        ml -400px
        // left 120px
        bottom -60px
        z-index 2
    &__dots
        display block
        pos absolute
        width 45%
        bottom 0
        right 15%
        margin-left auto
        text-align center
        padding-bottom 25px
        z-index: 1
    .swiper-pagination-bullet
        display inline-block
        width 11px
        height 11px
        border-radius 50%
        background-color o
        cursor pointer
        vertical-align middle
        margin-right 12px
        &:last-child
            margin-right 0 !important
        &-active
            width 15px
            height 15px
            bg-color bg
            border 3px solid o
            margin-right 9px

.slider
    width 100%
    @extend .foil
    background-image url(./../images/slider.png)
    &__wrap
        min-height 250px
        position relative
    &__block
        display block
        padding-top 40px
        padding-bottom 40px
    &__unit
        display block
        width 45%
        margin-left auto
        margin-right 15%
    &__title
        color o
        font-size 36px
        font-family SansRoundedC
    &__text
        font-size 16px

.numeric
    display block
    width 100%
    position absolute
    top -14px
    left 0
    text-align center
    z-index 5
    +mq-md()
        pa: 15px 0 
    +mq-xl()
        pa: 0 
        
    &__list
        display inline-block
    &__item
        display block
        float left
        padding 0 17px
        list-style none
    &__link
        width 53px
        height 53px
        border-radius 50%
        font-size 40px
        text-align center
        color #fff
        font-family SansRoundedC
        background-color o
        border 3px solid #fff
        line-height 1
        transition transform 0.1s linear
        bg-repeat no-repeat
        bg-position top center
        bg-size 90% 90%
        &--1:hover
            bg-image url(./../images/n1.png)
        &--2:hover
            bg-image url(./../images/n2.png)
        &--3:hover
            bg-image url(./../images/n3.png)
        &--4:hover
            bg-image url(./../images/n4.png)
            bg-position center center
        &--5:hover
            bg-image url(./../images/n5.png)
            bg-size 10px 10px
        &:hover
            transform scale(1.5) !important
            font-size 27px
    &__link.is-active
        transform scale(1.3)
.step
    display block
    width 100%
    &__title
        text-align center
        font-size 30px
        color #F0730D
        margin-bottom 20px
        position relative
        font-family SansRoundedC
        line-height 1
        padding-left 5px

    &__toggler
        display inline-block
        vertical-align middle
        padding-left 3px

        &:after
            top 5px
            display inline-block
            vertical-align text-top
            content url(./../images/arrow4.png)
    &__tutorial-toggler
        background-color red
        border-radius 50%
        width 30px
        height 30px
        display block
        color #fff
        float right
        transition .2s ease all
        &:hover
            background-color #f0730d



.half
    display block
    position absolute
    top 90px
    left 0
    z-index 10
    bg-color bg
    padding 10px 25px
    border 1px solid o
    border-radius 15px
    &__arrow
        position arelative
        &:before,
        &:after
            content ''
            position absolute
            left 50%
            transform translateX(-50%)
            top -20px
            border 10px solid transparent
            border-bottom 9px solid o
        &:after
            top -18px
            border-bottom 8px solid bg
    &--products
        top 300px
    &--group
        padding 10px 15px
    &__drop-slide
        display block
        // width 60px
        // float left
        max-width 111px
        margin-left auto
        margin-right auto
        padding 0 10px
        color #000
    &__group
        display block
        width 120px
        padding-top 5px
        text-align center
    &__group-block
        display inline-block
        vertical-align top
        width 60px
        margin-top 14px
        color #000
        margin-bottom 4px
        &:nth-child(3n)
            margin-right 0
        &:first-child,
        &:nth-child(2)
            margin-top 0
    &__drop-picture
        width 54px
        height 54px
        border-radius 50%
        margin 0 auto
        position relative
        &--pink
            bg-color pink
        &--rose
            bg-color rose
        &--lime
            bg-color lim
        &--brown
            bg-color brow
        &--yellow
            bg-color yel
        &--orange
            bg-color oran
        &.is-active:before
            content url(./../images/active-arrow.png)
            position absolute
            right -3px
            bottom -3px
    &__drop-name
        font-size 12px
        margin-top 4px
        text-align center
        cursor pointer
        &:hover
            text-decoration underline
    &__drop-picture img
        max-width 70%
    &__nav
        @extend .category__nav
        width 23px
        height 23px
        rop 44%
        &:before
            font-size 15px
        &--prev
            left 6px
        &--next
            right 6px
    &__product
        width 100%
        height 45px
        overflow hidden
        margin 0 auto 3px
        position relative
        cursor pointer

        &.is-active
            &:before
                content ''
                width 21px
                height 21px
                bg-repeat no-repeat
                bg-size cover
                bg-position center center
                bg-image url(./../images/active-icon.png)
                position absolute
                top 22px
                right 30px
    &__product img
        max-width 100%
        max-height 100%
    &__title
        font-size 16px
        font-family SansRoundedC
        color o
        margin-bottom 7px
        text-align center

.half
    display none

    &.is-active
        d block

// .calendar__unit:hover .half
//     d block

.zoom
    display none
    position absolute
    top 99%
    right 0
    z-index 10
    bg-color bg
    padding 10px 20px 10px 20px
    border 4px solid o
    border-radius 10px
    &__arrow
        position absolute
        top 0
        left 0
        width 100%
        &:before,
        &:after
            content ''
            position absolute
            right 10px
            transform translateX(-50%)
            top -23px
            border 12px solid transparent
            border-bottom 11px solid o
        &:after
            top -18px
            border-bottom 10px solid bg
    &__close
        display block
        width 15px
        height 15px
        position absolute
        right 4px
        top 4px
        cursor pointer
        font-weight 700
        &:before
            font-size 15px
            color o
        &:hover:before
            color o2
    &__picture img
        max-width 130px !important
    &__block
        width 100%
    &__img-mini
        width 42px
        overflow hidden
        position absolute
        top 4px
        left 4px
    &__icons
        text-align center
        padding-top 8px
        d flex
    &__icon
        display inline-block
        width 20px
        height 20px
        margin 0 5px
        @extend .foil
        &--1
            bg-image url(./../images/k1.png)
        &--1:hover,
        &--1.is-active
            bg-image url(./../images/k1-hov.png)
        &--2
            bg-image url(./../images/k2.png)
        &--2:hover,
        &--2.is-active
            bg-image url(./../images/k2-hov.png)
        &--3
            bg-image url(./../images/k3.png)
        &--3:hover,
        &--3.is-active
            bg-image url(./../images/k3-hov.png)

// !!!! ATTENTION 
// нельзя выравливать эту кнопку 
// top: 50%; transform: translateY(-50%)
// ломаются модалы, как бы это странно не звучало
.header__lk
    display block
    position relative
    width 60px
    height 60px
    @extend .foil
    flex-shrink: 0
    background-image url(./../images/lk.png)
    // position absolute
    // left 0
    z-index 55
    // top 5px
    // transform translateY(-50%)
    cursor pointer
    tr: 0.5s
    +media(from('xl'))
        &:hover 
            tr: 0.5s
            box-shadow 2px 2px 10px #fff
            br-radius: 100%
// .header__lk:after
//     content ''
//     position absolute
//     width 200px
//     height 30px
//     position absolute
//     bottom -16px
//     right 0
.header__lk-dropdown
    display none
    position absolute
    left auto
    left 0
    top 75px
    padding 15px 45px
    background-color #FBE2A9
    border-radius 12px
    font-size 12px
    color #000
    z-index 5
.header__lk-dropdown.is-opened
    display block
.header__lk-dropdown .modal__link
    margin-right 0
.header__lk-dropdown .calendar__link
    white-space nowrap
    margin-bottom 14px
    margin-top 14px
.header__lk-link
    display inline-block
    width 35px
    height 35px
    margin 0 5px
.header__top-link
    text-align center
.header__lk-exit
    display block
    fw: 700
    color #000
    text-decoration underline
.header__lk-exit:hover
    text-decoration none
.header__lk-dropdown:before,
.header__lk-dropdown:after
    content ''
    position absolute
    right 20px
    top -15px
    border 8px solid transparent
    border-bottom 7px solid bg
.header__lk-dropdown:after
    top -15px
.calendar__right .category__close
    width 14px
    height 14px
    top 1px
    right 1px
.calendar__right .category__slide
    padding 10px
    height 100% !important
.calendar__right .calendar__img
    height 100%
    display flex
    align-items center
    justify-content center
    display -webkit-flex
    -webkit-align-items center
    -webkit-justify-content center

.calendar__right .category__close:before
    font-size 9px
    left 52%
.numeric__list:before ,
.numeric__list:after
    content " "
    display table
    width 0
    height 0
    visibility hidden
.numeric__list:after
    clear both
.category__wrap-slider .calendar__title ,
.category__wrap-slider.is-active .calendar__title
    display none
.category__wrap-slider .category__close--mobile,
.category__wrap-slider.is-active .category__close--mobile,
.category__wrap-slider .category__close--global,
.category__wrap-slider.is-active .category__close--global
    display none


@media(max-width 1200px)
    .calendar
        padding-left 0
        padding-right 0
    .category__name
        font-size 14px




@media(max-width 960px)
    .slider-wrapper__child
        display none
    .slider__title
        text-align center
    .slider__unit
        width 100%
        margin 0
        padding-left 30px
        padding-right 30px
    .calendar__left
        width 100%
        float none
        margin-bottom 30px
    .calendar__right
        width 100%
        float none
    .calendar__range
        width 100%
        padding-right 45px
        padding-left 45px
    .calendar__arrow--next
        right 0
    .calendar__arrow--prev
        left 0
    .calendar__arrow
        top -62px
    .calendar__arrow--next .calendar__help
        transform none
        left auto
        right 0
    .calendar__arrow--prev .calendar__help
        transform none
        left 0
    .calendar__arrow--next .calendar__help:before
        transform none
        left auto
        right 9px
    .calendar__arrow.is-error .calendar__help:after
        border-top 7px solid transparent
    .calendar__arrow--prev .calendar__help:before,
    .calendar__arrow--prev .calendar__help:after
        transform none
        left 9px
    .calendar__right:before,
    .calendar__right:after
        content " "
        display table
        width 0
        height 0
        visibility hidden
    .calendar__right:after
        clear both
    .calendar__right
        padding-top 0
    .category__block
        display block
        float left
        width 30%
        margin-right 5%
    .category__block:nth-child(3n)
        margin-right 0
    .category__name
        text-align center
    .category__nav--prev
        left 1px
    .category__nav--next
        right 1px
    .category__close
        width 15px
        height 15px
    .category__close:before
        font-size 10px
    .category__wrap-slider
        padding-left 16px
        padding-right 16px
    .calendar__picture
        height 100%
    .calendar__unit-wrap.is-hoverable:hover:before,
    .calendar__hover
        content ''
        display none !important
    .calendar__unit-wrap.is-hoverable:hover .calendar__unit
        border-radius 0
        z-index 3
    .calendar__food
        display none
    .slider__dots
        width 100%
        margin-left 0
        margin-right 0
    .slider__block
        padding-bottom 20px
    .zoom.is-active
        display block
    .half
        border-width 4px
        &__arrow:before,
        &__arrow:after
            top -23px
            border 12px solid transparent
            border-bottom 11px solid o
        &__arrow:after
            top -18px
            border-bottom 5px solid o
    .header__lk-dropdown
        top 55px
    .header__lk-dropdown:before, .header__lk-dropdown:after
        right 16px
    .header__lk-dropdown
        text-align center
    .numeric
        top 5px
    .slider__wrap
        min-height 430px
    .calendar__row:first-child .half.half--group,
    .calendar__row:first-child .half.half--products
        right auto
        left -5px !important
        transform none !important
    .calendar__row:first-child .half.half--group .half__arrow:before,
    .calendar__row:first-child .half.half--group .half__arrow:before,
    .calendar__row:first-child .half.half--products .half__arrow:before,
    .calendar__row:first-child .half.half--products .half__arrow:after
        right auto
        left 40px

    .calendar__row:last-child .half.half--group,
    .calendar__row:last-child .half.half--products
        left auto !important
        transform none !important
        right 0px
    .calendar__row:last-child .half__arrow:before,
    .calendar__row:last-child .half__arrow:before,
    .calendar__row:last-child .half.half--products .half__arrow:before,
    .calendar__row:last-child .half.half--products .half__arrow:after
        left auto
        right 13px
    .half.half--group .half__arrow:after
        border-bottom 5px solid transparent
    .numeric
        position relative
        top 0
        left 0
        background-color #f0730d
    .numeric__list
        margin-bottom -10px
    .calendar__right .category__close
        right 5px
    .calendar__right .category__close:before
        left 50%
    .calendar__right .category__wrap-slider
        padding-left 0
        padding-right 0
    .slider-wrapper__dots
        width 100%
        right 0
        padding-bottom 0
        bottom auto
        top 245px
    .slider-wrapper__child
        display none
    .calendar__top .calendar__link
        margin-bottom 15px

@media(max-width 959px)
    .header__lk
        width 32px
        height 32px


@media (min-width: 480px) and (max-width 720px)
    .slider-wrapper__dots
        top 208px !important


@media(max-width 720px)
    .calendar__link
        display block
        width 75%
        margin-left auto
        margin-right auto
        margin-bottom 15px
    .calendar__link:last-child
        margin-right auto
    .calendar__plus
        width 10px
        height 10px
        position absolute
        bottom 5px
        right 3px
    .calendar__measure
        padding-top 50px
    .calendar__add
        width 35px
    .calendar__block-measure
        display block !important
        position relative
    .calendar__half
        width calc(100% - 40px)
    .calendar__unit,
    .calendar__block-measure
        height 52px
    .calendar__food
        display none
    .calendar__icon
        width 10px
        height 10px
        top 2px
        right 2px
    .calendar__date
        top -17px
        font-size 12px
    .calendar__measure
        background-size 60% 60%
        background-position left top
        padding-top 30px
        padding-left 0
    .calendar__row.is-active:before
        top -20px
        left -3px
        right -2px
        bottom -3px
    .calendar__row.is-active:after
        width 4px
        bottom 4px
        right -2px
        border-bottom-right-radius 50px
    .category__name
        font-size 13px
    .calendar__picture
        padding 2px
    .numeric__item
        padding 0 5px
    .half__product.is-active:before
        right 8px

    .slider__wrap
        min-height 345px !important
    h1.product-card__title
        display block
        width 200px
        margin-left 150px
    li.opener__showup
        padding-bottom 30px
    .product-card__spoiler-toggler
        margin-top 0
    .product-card__controls
        bottom 25px
    .half__drop-slide
        max-width 90px
    .product-card__controls
        padding-left 20px
        padding-right 0
    .calendar__row:last-child .half__arrow:before,
    .calendar__row:last-child .half__arrow:before,
    .calendar__row:last-child .half.half--products .half__arrow:before,
    .calendar__row:last-child .half.half--products .half__arrow:after
        right -1px !important
    .calendar__row:first-child .half.half--group .half__arrow:before,
    .calendar__row:first-child .half.half--group .half__arrow:before,
    .calendar__row:first-child .half.half--products .half__arrow:before,
    .calendar__row:first-child .half.half--products .half__arrow:after
        left 26px !important
    .calendar__row:nth-child(6) .half__arrow:before,
    .calendar__row:nth-child(6) .half__arrow:before,
    .calendar__row:nth-child(6).half.half--products .half__arrow:before,
    .calendar__row:nth-child(6) .half.half--products .half__arrow:after
        left auto
        right 55px
    .calendar__row:nth-child(2) .half.half--group,
    .calendar__row:nth-child(2) .half.half--products
        right auto
        left -57px !important
        transform none !important
    .calendar__row:nth-child(6) .half.half--group,
    .calendar__row:nth-child(6) .half.half--products
        left auto !important
        transform none !important
        right -57px
    .calendar__row:nth-child(2) .half__arrow:before,
    .calendar__row:nth-child(2) .half__arrow:before,
    .calendar__row:nth-child(2).half.half--products .half__arrow:before,
    .calendar__row:nth-child(2) .half.half--products .half__arrow:after
        left 79px
    .calendar__right .category__name
        height 19px
    .calendar__right
        position relative
    .category__wrap-slider.is-active
        display block
        width 185px
        position absolute
        top 19px
        padding 10px 25px 0px
        border 4px solid #f0730d
        height auto
        border-radius 15px
        z-index 5
    .category__block:first-child .category__wrap-slider.is-active
        left 0
    .category__block:nth-child(2) .category__wrap-slider.is-active
        left 50%
        transform translateX(-50%)
    .category__block:nth-child(3) .category__wrap-slider.is-active
        right 0
    .category__wrap-slider.is-active .category__close--mobile,
    .category__wrap-slider.is-active .category__close--global
        display block
    .category__wrap-slider.is-active .category__close--desktop
        display none
    .category__wrap-slider.is-active .calendar__title
        display block
        font-size 14px
        font-weight 700
        margin-bottom 10px
        color #f0730d
        text-align center
    .category__wrap-slider.is-active .category__close--mobile:hover
        text-decoration none
        background-color transparent
    .calendar__right .category__wrap-slider.is-active .category__close:before
        font-size 14px
    .calendar__right .category__wrap-slider.is-active .category__close
        top 5px
        width 22px
        height 22px
    .category__wrap-slider.is-active .category__close--mobile
        position static
        display block
        width 100% !important
        text-align center
        margin-top 10px
        font-size 12px
        text-decoration underline
        color #f0730d
    .category__wrap-slider .calendar__img img
        cursor pointer
    .category__slider .slides__list, .category__slider .swiper-wrapper
        height 100% !important    
    .calendar__top
        padding-bottom 15px

@media(max-width 480px)
    .calendar__picture
        padding 5px
    .calendar__unit, .calendar__block-measure
        height 40px
    .calendar__measure
        font-size 12px
    .calendar__plus
        width 7px
        height 7px
        bottom 4px
        right 7px
    .calendar__measure
        background-size 50% 50%
        padding-top 24px
    .calendar__row.is-active:before
        border-radius 12px
    .calendar__link
        font-size 12px
    .slider-wrapper__numeric
        width 100%
        overflow-y hidden
        overflow-x auto
        position relative
        z-index 4
    .numeric
        width 100%
        background-color transparent
        padding-bottom 15px
    .numeric__list
        display inline-block
        min-width 388px
        padding 8px 5px 0
        background-color #f0730d
    .slider-wrapper__numeric
        background-color #f0730d
    .numeric__link:hover
        transform scale(1.3) !important
    .app
        min-width 320px
        // overflow hidden
        padding-bottom 215px
    .header__lk-dropdown
        width 315px

    h1.product-card__title
        font-size 18px
        width 110px
    .category__name
        font-size 12px !important
    .product-card__controls
        width 73%
        padding-left 15px
    .calendar__row:last-child .half__arrow:before,
    .calendar__row:last-child .half__arrow:before,
    .calendar__row:last-child .half.half--products .half__arrow:before,
    .calendar__row:last-child .half.half--products .half__arrow:after
        right -8px !important
    .calendar__row:last-child .zoom__arrow:before,
    .calendar__row:last-child .zoom__arrow:after
        right -8px !important
    .calendar__row:first-child .zoom__arrow:before,
    .calendar__row:first-child .zoom__arrow:after
        left 15px !important
    .calendar__row:first-child .half.half--group .half__arrow:before,
    .calendar__row:first-child .half.half--group .half__arrow:before,
    .calendar__row:first-child .half.half--products .half__arrow:before,
    .calendar__row:first-child .half.half--products .half__arrow:after
        left 20px !important
    .calendar__row:nth-child(6) .half.half--group,
    .calendar__row:nth-child(6) .half.half--products
        right -40px
    .calendar__row:nth-child(2) .half.half--group,
    .calendar__row:nth-child(2) .half.half--products
        left -40px
    .calendar__row:nth-child(2) .half__arrow:before,
    .calendar__row:nth-child(2) .half__arrow:before,
    .calendar__row:nth-child(2).half.half--products .half__arrow:before,
    .calendar__row:nth-child(2) .half.half--products .half__arrow:after
        left 72px
    .calendar__row:nth-child(6) .half__arrow:before,
    .calendar__row:nth-child(6) .half__arrow:before,
    .calendar__row:nth-child(6).half.half--products .half__arrow:before,
    .calendar__row:nth-child(6) .half.half--products .half__arrow:after
        right 32px
    .calendar__row:nth-child(2) .zoom
        right auto !important
        left -40px
    .zoom .zoom__arrow:before,
    .zoom .zoom__arrow:after
        right -9px
    .calendar__row:nth-child(2) .zoom .zoom__arrow:before,
    .calendar__row:nth-child(2) .zoom .zoom__arrow:after
        right auto !important
        left 55px
    .calendar__right .category__close
        right 3px

    .calendar__right .category__name
        height 17px
    .slider-wrapper__dots
        top 224px !important
    .calendar__top .calendar__link
        margin-bottom 10px !important
    .numeric__item
        padding 0 5px



.calendar
    &__
        &date-picker
            pos absolute !important
            bottom 100%
            left 50%
            transform translateX(-50%)
            z-index 1000
            mb 26px

            @media(max-width 960px)
                left 0% !important
                transform none !important

    .vdp-datepicker
        &__
            &calendar
                bg-color #fbe2a9
                br-radius .5rem
                box-shadow 0 0 7px 1px rgba(0,0,0,0.1)
                overflow hidden
                border 0
                w 240px
                pa .5rem


                & .cell
                    h 25px
                    fh @h
                    color darken(#f0730d, 20%)

                & header
                    ma 0 - @pa
                    mb 0

                    bg-color #f0730d
                    color #fff
                    clear fix

                    & .next:after
                        border-left-color #fff
                    & .prev:after
                        border-right-color #fff

    .vdp-datepicker__calendar header .prev:not(.disabled):hover,
    .vdp-datepicker__calendar header .next:not(.disabled):hover,
    .vdp-datepicker__calendar header .up:not(.disabled):hover
        bg-color darken(#f0730d, 5%)
        color #fff

    .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
    .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
    .vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover
        border-color #f0730d

    .vdp-datepicker__calendar .cell.selected:hover
        bg-color darken(#f0730d, 5%)
    .vdp-datepicker__calendar .cell.selected
        bg-color #f0730d
        color #fff
        border-radius 9999