.poll-wrapper
  br: 3px solid #FFFAF7
  br-radius: 70px
  bg: #FFFAF7
  pos: relative
  &__
    &content
      +media(from('md'))
        w: 100%

    &element
      br-radius: 70px
      br: 10px solid $c.orange
      d: flex 
      ai: center 
      jc: space-between
      f-dir: column
      pa: 40px 20px
      +media(from('xl'))
      // +media(from('mac'))
        pa: 55px 40px 40px
    &close 
      color: #B6AAA3;
      border: 2px solid #B6AAA3;
      fs 28px
      w: 32px
      h: 32px 
      br-radius: 50%
      d: flex
      lh: 1
      ai: center 
      jc: center
      top: 17px 
      right: 50px
      pos: absolute
      +media(from('xl'))
        fs 28px
        w: 24px
        h: 24px 
        top: 30px 
        right: 50px
        transition: 0.5s
        &:hover 
          color: $c.orange
          transition: 0.5s
.poll-        
  &text 
    font-family: Open Sans;
    font-style: normal
    font-weight: 400
    line-height: 1.5
    letter-spacing: 0em
    text-align: center
    fs: 13px
    &--
      &bold 
        fw: 700
        mt: 20px
        +media(from('xl'))
          mt: 44px
      &left
        ta: left 

  &child-decor
    width 100px 
    h: 100%
    d: block 
    ma: 20px auto

    &__
      &img 
        d: block 
        w: 100%
        h: auto  
  &gift-decor
    width 100px 
    h: 100%
    d: block 
    ma: 20px auto

  &title
    font-family: SansRoundedC
    font-style: normal
    font-weight: 400
    line-height: 1.3
    fs: 18px
    letter-spacing: 0em;
    color: $c.orange
    text-align: left;
    &--
      &centered 
        d: flex 
        ai: center 
        jc: center 
        ta: center
    &__
      &icon
        ml: 10px

    +media(from('xl'))
      fs: 25px

.poll-btn
  bg: $c.orange
  box-shadow: 0px 8px 0px #E65A05
  d: flex
  ai: center 
  jc: center
  br-radius 16px
  fw 400
  color white
  ff SansRoundedC
  transition: 0.5s
  fs: 14px
  w: 206px 
  h 40px
  ma: 0 auto
  +media(from('xl'))
    w: 150px 
    br-radius 10px
    h 36px
    fs: 16px
    box-shadow: 0px 6px 0px #E65A05
    &:hover 
      transition: 0.5s
      box-shadow: none
      transform: translateY(6px)
      pos: relative
  &--
    &grey
      bg: #B6AAA3
      box-shadow: 0px 8px 0px #928881
      +media(from('xl'))
        box-shadow: 0px 6px 0px #928881

    &with_ma  
      mt: 20px
  &.is-disabled   
    pointer-events: none
    opacity 0.5
.large-modal
  left: 50% !important
  transform: translateX(-50%) !important
  +media(from('md'))
    width 460px !important
  +media(from('xl'))
    width 460px !important
    h auto !important

