.section
  pt 60px
  +media(from('xl'))
    pt 0
  &__title 
    font-family: SansRoundedC;
    font-size: 36px;
    line-height: 36px;
    color: #F9F9F9;
    fw 400
    pt 22px
    &--center 
      ta: center 
    &--link 
      d flex
      +media(from('xl'))
        tr 0.5s
        &:hover 
          color: $c.red 
          tr 0.5s
      span 
        fs 12px
        lh 36px
        pr 30px
        +media(from('xl'))
          fs 17px
          lh 48px
          pr 30px
    +media(from('xl'))
      pt 80px
      fs 48px 
      pb 20px
      lh 49px
  &__subtitle 
    color: $c.red
    font-family: SansRoundedC
    font-size: 24px
    line-height: 30px
    text-align: center
    pa 35px 0
    +media(from('xl'))  
      fs 24px 
      lh 30px
      pa 100px 0 30px
.video-card 
  background #F9F9F9
  box-shadow 0px 4px 30px rgba(238, 115, 37, 0.16)
  br-radius 12px
  pa 20px 
  d flex 
  ai center 
  mt 20px
  jc center 
  f-dir column
  +media(from('xl'))
    mt 30px 
    f-dir row 
    ai center 
    pa 36px
  &__date 
    ff 'Open Sans'
    font-style normal
    fw bold
    mt 4px
    fs 11px
    lh 21px
    color #333333
    opacity 0.4  
  &__title 
    fd none 
    ff RotondaC
    fs 16px
    lh 18px
    fw 700
    mt 12px
    color #EE1B23
    +media(from('xl'))
      fs 22px 
      lh 24px
      tr 0.5s
      &:hover 
        color: $c.orange 
        tr 0.5s
  &__desc
    ff: 'Open Sans'
    fw: bold
    fs: 14px
    lh: 24px
    mt 6px
    color: #2B0000
    opacity: 0.7
  &__img 
    d block
    w 100% 
    h auto
  &__right-side 
    d flex 
    w 100%
    f-dir column
  &__left-side 
    pos relative  
    +media(from('xl'))
      w 445px
      flex-shrink 0
      mr 20px
  &__left-side-article
    pos relative  
    +media(from('xl'))
      w 200px
      flex-shrink 0
      mr 20px

.video-card, .article-card
  +media(from('xl'))
    align-items: flex-start

.mini-video-card 
  br-radius 12px
  pa 20px 
  d flex 
  ai center 
  mt 20px
  jc center 
  f-dir column 
  
  +media(from('xl'))
    mt 0px 
    pa 0 
    w 250px

  &__title 
    fd none 
    ff RotondaC
    fs 12px
    lh 16px
    fw 700
    mt 12px
    color #EE1B23
    +media(from('xl'))
      tr 0.5s
      &:hover 
        color: $c.orange 
        tr 0.5s
  
  &__img 
    d block 
    w 100% 
    h auto 
  &__right-side 
    d flex 
    w 100%
    f-dir column
  &__left-side 
    pos relative  
    +media(from('xl'))
      w 250px
      flex-shrink 0
.cards-wrapper
  bg white
  +media(from('xl'))      
    d flex 
    ai center 
    jc space-between 
    max-w 1172px 
    ma 0 auto
    br-radius 12px
    
.article-cards-wrapper
  bg #FECC6C
  +media(from('xl'))      
    d flex 
    ai center 
    jc space-between 
    max-w 1172px 
    ma 0 auto
    br-radius 12px

.article-list-desciprion
  margin-top: 10px
  margin-bottom: 60px

.play-video
  bg: $c.orange
  w 56px 
  h 56px 
  br-radius 100%
  d block
  pos absolute 
  top 50% 
  tr 0.5s
  left 50% 
  transform: translate(-50%, -50%)
  +media(from('xl'))
    &:hover 
      tr 0.5s
      box-shadow: 0 0 0 10px $c.orange, 0 0 0 13px $c.grey--light, 0 0 0 20px $c.orange, 0 0 0 23px $c.grey--light;
      animation: effect1_alt 1s linear 0s infinite ;
    &:active
      box-shadow: 0 0 0 18px $c.orange, 0 0 0 21px $c.grey--light, 0 0 0 20px $c.orange, 0 0 0 23px $c.grey--light;

  &:after 
    content ''
    pos absolute 
    top 50% 
    left 55% 
    transform: translate(-50%, -50%)
    width: 0;
    height: 0;
    border-top: 11px solid transparent;
    border-left: 22px solid white;
    border-bottom: 11px solid transparent;


.videoPopup
  width: 280px !important
  height: auto !important
  left 50% !important
  transform translateX(-50%) !important
  & div 
    h 100%
  +media(from('sm'))
    width: 500px !important
    height: 300px !important
  +media(from('md'))
    width: 700px !important
    height: 400px !important    
  iframe 
    w 100%
    h 100%
.close-popup 
  position: absolute 
  top 0
  right 0
  cursor pointer
  border-radius 0 0 0 5px 
  color: $c.orange
  d flex 
  ai center 
  z-index 55555
  fs 32px
  fw 700
  jc center  
  w 30px 
  fd none
  tr 0.5s
  h 30px    
  &--is-white
      color: white 
      fs 70px
  +media(from('xl'))
    &:hover
      tr 0.5s
      color: $c.red


.default-btn
  d flex 
  ai center 
  jc center 

  font-family: SansRoundedC;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
  color: #F9F9F9
  w 280px 
  h 60px
  border: 3px solid #F9F9F9;
  border-radius: 37px;
  ma 36px auto
  tr 0.5s  
  +media(from('xl'))
    ma 50px auto 20px
    &:hover 
      br-color: $c.red 
      color: $c.red
      tr 0.5s  
  &--
    &full
      color white
      background: #EE7325;
      border-radius: 37px;
      br-color #EE7325
      +media(from('xl'))
        w 430px
        &:hover 
          bg-color transparent
    &mini
      pa 10px 15px 
      h: 32px
      ma: 0
      br-width: 2px
      w: auto
      +media(from('xl'))
        ma: 0
        tr: 0.5s
        &:hover 
          br-color: #fff 
          color: #fff
          opacity: 0.7
          tr: 0.5s
    &abs 
      pos: absolute 
      left: 0
      z-index: 55
      +media(to('md'))
        top: -9px
    &circle 
      w: 32px
      h: 32px

    &is-visible-xl    
      +media(to('lg'))
        d: none
    &is-disable-xl    
      +media(from('xl'))
        d: none
  &__ 
    &icon
      fs: 30px 
      mr: 5px
      &--
        &mini
          fs: 16px

.schema-info
  d none
