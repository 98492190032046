.banner
  &__
    &image-cont
      position:relative
      overflow:hidden
      width:300px
      height:600px
    &image-back
      position:relative
    &image-front
      position:absolute
      top:0
      left:0