$default-space = 13px

$default-space-half = $default-space / 2

$pp-grid-space = $space-md
$pp-grid-space-group = $space-group

.{$PREFIX}row
  --default-space $space-md
  display: flex
  flex-wrap: wrap
  margin-right: 'calc(-1 * %s)' % $default-space !important
  margin-left: 'calc(-1 * %s)' % $default-space !important
  margin-top: 'calc(-1 * %s)' % $default-space !important
  margin-bottom: 'calc(-1 * %s)' % $default-space !important
  list-style: none
  padding: 0
  &--
    &cards
    &margin_sm
      .{$PREFIX}cell
        margin-top: $default-space-half !important
        margin-bottom: $default-space-half !important
        --default-space $space-sm
    &margin_md
      --default-space $space-md
    &margin_lg
      --default-space $space-md
    &margin_xl
      --default-space $space-xl
      margin-right: 'calc(-1 * %s)' % var(--default-space) !important
      margin-left: 'calc(-1 * %s)' % var(--default-space) !important
      margin-top: 'calc(-1 * %s)' % var(--default-space) !important
      margin-bottom: 'calc(-1 * %s)' % var(--default-space) !important
      .{$PREFIX}cell
        padding-right: var(--default-space)
        padding-left: var(--default-space)
        margin-top: var(--default-space)
        margin-bottom: var(--default-space)
    &margin_
      &negative
        & > .{$PREFIX}cell
          margin-top 0
          margin-bottom 0
    &column
      flex-direction column
    &column-
      for $size, $value in $media-group
        &{$size}
          flex-direction column
          @media(min-width: $value)
            flex-direction row
    

    &default
      width auto !important
    &nowrap
      flex-wrap: nowrap

    &justification_
      &between
        justify-content space-between
      &right
        justify-content flex-end
      &center
        justify-content center

    &justification_
      &between-
        for $size, $value in $media-group
          &{$size}
            @media(min-width: $value)
              justify-content space-between
      &right-
        for $size, $value in $media-group
          &{$size}
            @media(min-width: $value)
              justify-content flex-end
      &center-
        for $size, $value in $media-group
          &{$size}
            @media(min-width: $value)
              justify-content center

        
    &alignitem_
      &center
        align-items: center

      &top
        align-items: flex-start

      &bottom
        align-items: flex-end


    &space_
      &none
        +both-adaptive-block()
          --grid-space: 0

      for $size, $value in $pp-grid-space-group
        &{$size}
          +both-adaptive-block()
            --grid-space $value


.{$PREFIX}cell
  padding-right: $default-space
  padding-left: $default-space
  margin-top: $default-space
  margin-bottom: $default-space
  min-width: 0

  &--
    &alignment_
      &center
        align-self: center

      &top
        align-self: flex-start

      &bottom
        align-self: flex-end
    &visible_
      for $size, $value in $media-group
        &{$size}
          display none
          @media(min-width: $value)
            // padding-left 0
            display block
    &disable_
      for $size, $value in $media-group
        &{$size}
          display none
          @media(max-width: $value)
            // padding-left 0
            display block
    
    

      
            
      