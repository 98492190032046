.first-view 
  h calc(100vh - 60px)
  d flex 
  ai center 
  jc center 
  f-dir column

  &__title 
    font-family: SansRoundedC;
    font-size: 48px
    line-height: 49px
    text-align: center
    color: #F9F9F9
    +media(from('xl'))
      font-size: 80px;
      line-height: 72px;
  &__text   
    font-family: Open Sans;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    pt 25px
    max-w 430px 
    ma 0 auto
    color: rgba(#2B0000, 0.6)
.show-more 
  d flex 
  f-dir column 
  ai center 
  mt 66px
  jc center 
  +media(from('xl'))
    mt 112px
  &__text 
    
    font-family: SansRoundedC;
    font-size: 16px;
    line-height: 12px;
    text-align: center;
    color: #F9F9F9;
    pb 20px
  &__icon 
    
    color: #F9F9F9;
    transform: translateY(0px)rotate(-90deg) 
    animation  pulseArr 1s infinite linear  
@keyframes pulseArr 
  0% 
    transform: translateY(0px)rotate(-90deg) 
  50% 
    transform: translateY(10px)rotate(-90deg)  
  100% 
    transform: translateY(0px)rotate(-90deg) 
  



.child-info
  background: #F9F9F9;
  box-shadow: 0px 4px 30px rgba(238, 115, 37, 0.16);
  border-radius: 12px;
  pa 30px 10px
  max-w 830px 
  ma 0 auto 
  +media(from('xl'))
    pa 70px
  @media(min-width: 1200px) and (max-width: 1441px)  
    pa 20px
  &__title
    font-family: SansRoundedC;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    color: #EE1B23;
    +media(from('xl'))
      fs 24px 
      lh 30px
      max-w 470px 
      ma 0 auto 
      ta center
.factor  
  mt 25px  
  +media(from('xl'))
    d flex
    ai center 
    jc flex-start
  @media(min-width: 1200px) and (max-width: 1441px)   
    mt 15px
  &__img-wrap 
    ma 0 auto
    +media(from('xl'))  
      ma 0 
      w 70px
      mr 30px
  &__img
    d block 
    ma 0 auto
  &__text 
    font-family: SansRoundedC;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: rgba(#2B0000, 0.8)
    pt 10px
    +media(from('xl'))
      ta left
.steps-wrap 
  max-w 1075px 
  ma 0 auto
.calendar-step 
  mt 36px 
  +media(from('xl'))
    d flex 
    jc space-between
    ai flex-start
    mt 90px
    &:nth-child(even)
      f-dir row-reverse
  &__title
    mt 20px
    font-family: SansRoundedC;
    font-size: 36px;
    line-height: 40px;
    color: #EE1B23;
    +media(from('xl'))  
      mt 0
      fs 48px
      lh 49px
  &__img 
    w 100% 
    d block  
    +media(from('xl'))  
      w 520px 


  &__row 
    mt 20px
    +media(from('xl'))  
      w 472px
      mt 0
  &__text 
    mt 10px
    & p

      font-family: Open Sans;
      font-size: 14px;
      line-height: 24px;
      color: rgba(#2B0000, 0.8)
      pb 20px


+media(from('xl'))        
  &::-webkit-scrollbar-track
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 5px;
    background-color: #F5F5F5;

  &::-webkit-scrollbar
    width: 5px;
    height 5px
    background-color: #F5F5F5;
  &::-webkit-scrollbar-thumb

    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $c.orange


.modal-with-apple
  bg-image url('/static/images/iStock-178395953 3.e5dde036b927.png')
  h 485px 
  bg-position center 
  bg-size cover
  d flex 
  ai center 
  jc flex-start 
  pt 160px
  f-dir column
  +media(from('xl'))
    h 637px
    pt 280px
    w 716px
    bg-size auto
  .info-modal
    &__title 
      font-family: SansRoundedC;
      font-size: 20px;
      line-height: 22px;
      text-align: center
      color: #F9F9F9
      +media(from('xl'))
        w 325px
    &__btn 
      w 260px 
      ma 50px  auto 0
  .input-group
    w 260px 
    ma 0 auto
    &__input 
      color white
      br-bottom-color white
      &::-webkit-input-placeholder
          color: white
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder
    color: white !important



.transparent-modal
  bg-color transparent
  +media(from('xl'))
    h 637px !important
    w 716px !important
    left 50% !important
    transform translateX(-50%) !important


.form-load
  pos absolute 
  left 0 
  top 0 
  w 100%
  overflow hidden
  border-radius 12px
  h 100% 
  bg: rgba($c.orange, 0.5)
  z-index 999999999
  d flex


.white-form
  background: #F9F9F9
  box-shadow: 0px 4px 30px rgba(238, 115, 37, 0.16)
  border-radius: 12px
  pos relative
  pa 10px
  max-w 590px 
  ma 0  auto
  +media(from('xl'))  
    ma 100px  auto 50px
    pa 65px
  .info-modal 
    &__title 
      font-family: SansRoundedC;
      font-size: 20px;
      line-height: 22px;
      text-align: center;
      mt 10px
      color: #EE1B23
      +media(from('xl'))
        font-size: 28px;
        line-height: 30px;
    &__text 
      font-family: Open Sans;
      font-size: 14px;
      line-height: 24px;
      mt 25px
      text-align: center;
      color: rgba(#2B0000, 0.6)
    &__btn 
      bg: $c.orange
      br-color: $c.orange
      color white
      +media(from("xl"))
        &:hover
          bg white

.success-message
  pa 20px 
  &--full
    h 500px 
    d flex
    f-dir column 
    ai center 
    jc center
    w 100%
    ma 0 auto
  &__title 
    fs 17px 
    pb 20px 
    fw 700
    ff SansRoundedC
    color: $c.orange
    text-transform: uppercase
    ta center 
  &__text 
    fs 13px 
    font-family: Open Sans;
    ta center       

.fruits-pattern
  background-repeat: no-repeat
  bg-image url(/static/images/p0.png), url(/static/images/p2.png), url(/static/images/p3.png), url(/static/images/p4.png), url(/static/images/p5.png), url(/static/images/p8.png), url(/static/images/p9.png)
  background-position: 100% 0, 87.5% 29% , 88.25% 71.6%, 99% 94.01%, -2.8% 2.8%, 0% 66%, 0% 95%
  bg-size 125px 

  +media(from('md'))
    bg-size auto 
    bg-image url(/static/images/p0.png), url(/static/images/p2.png), url(/static/images/p3.png), url(/static/images/p4.png), url(/static/images/p5.png), url(/static/images/p6.png), url(/static/images/p7.png), url(/static/images/p8.png), url(/static/images/p9.png)
    background-position: 100% 0, 87.5% 29% , 88.25% 71.6%, 99% 94.01%, 16.2% 2.8%,  0% 5%, 17% 25%, 0% 66%, 0% 95%
.form-fruits-pattern
  +media(from('md'))
    background-repeat: no-repeat
    bg-image url(/static/images/fp1.png), url(/static/images/fp2.png), url(/static/images/fp3.png), url(/static/images/fp4.png), url(/static/images/fp5.png), url(/static/images/fp6.png)
    background-position: 98% 0,100% 112%,76.25% 104.6%,20% -2.99%,0% 6.8%,8% 100%
.video-page-pattern
  +media(from('md'))
    bg-size auto 
    background-repeat: no-repeat
    bg-image url(/static/images/vp1.png), url(/static/images/vp2.png), url(/static/images/vp3.png), url(/static/images/vp4.png), url(/static/images/vp5.png), url(/static/images/vp6.png), url(/static/images/vp7.png), url(/static/images/vp8.png), url(/static/images/vp9.png), url(/static/images/vp10.png), url(/static/images/vp11.png)
    background-position: 100% 0%, 93% 5%,100% 20.6%, 100% 37%, 100% 56%, 100% 85%, 100% 88%, 8% 26%, 0% 77%, 0% 47%, 0% 65%