.app
  background url('./../images/app-bg.jpg') no-repeat center
  background-size cover
  min-height 100vh
  padding-bottom 150px
  position relative
  +mq-md()
    pb: 180px
  +mq-xl()
    pb: 150px
  &--color-bg 
    bg linear-gradient(180deg, #FAB442 3.12%, #FAA542 98.96%), #FAB442;
  &__
    &header-wrap 
      d: flex 
      height: 100%;
      display: flex;
      justify-content: space-between;
      padding 0 8px
      ai: center 
      color: $c.white
      // pos: relative
      +media(from('xl'))
        padding 0 50px

    &preloader
      pos fixed
      top 0
      left 0
      height 100%
      width 100%
      z-index 9999999999

      bg #fff
    &header
      padding 15px 0
      background-color  #EE7325
      pos: relative
      +media(from('xl'))
        h 100px
      &--fixed
        position absolute
        @media (min-width 960px)
            position relative
      &.on-overlay
        z-index 9999
    &title
      text-align center
      position relative
      font-family SansRoundedC
      font-weight 100
      fs 13px
      padding 0 8px
      color #F6F6F6
      white-space nowrap
      +media(from('mxs'))
        fs 18px
      +media(from('md'))
        w 100%
        fs: 30px
      +media(from('xl'))
        font-size: 48px;
        d flex 
        lh 1
        ai center 
        jc center
        w 100%
        background-repeat no-repeat
        background-position 0px center, 70px center, 140px center, 88% center
      &--nostyle
        text-align: center
        pa 38px 0
        w auto
        white-space: initial
        ma 0 auto
        +media(from('xl'))
          background-image none
          padding: 57px 0 43px

          background-position 0px center, 110px center, 210px center, 88% center
      &.bged
        background: url(./../images/app-bg.jpg) no-repeat center;
        background-size: cover;
        @media(min-width 960px)
          background: transparent
    &container
      &.is-
        &loading
          display none

    &loading
      pos absolute
      top 50%
      left 50%
      transform translateX(-50%) translateY(-50%)
    &footer

      position absolute
      padding 17px 5px
      background-color  #f0730d
      bottom 0px
      text-align center
      +media(from('xl'))
        padding 30px 50px
      & a
        color #fff
        text-decoration underline
        transition: 0.5s
        +media(from('xl'))
          &:hover 
            opacity: 0.7
            transition: 0.5s

    &footer-title
      fs 14px
      color #fff
      margin-bottom 14px
      +media(from('xl'))
        d flex
        f-dir row
        ai center
        jc space-between 
        .meth 
          // w 720px
          ta right 
          mb 0 !important
          ml: 40px
        .conf 
          ta left  
          w: 420px
    &links 
      +media(from('xl'))
        d: flex
        ai: center
    &footer-el
      &--conf, &--order, &--recomendation
        +media(from('xl'))
          width auto
          float none
          display inline-block
          font-size 12px
      &--order
        +media(from('xl'))
          margin 0 96px


sub, sup 
  fs smaller
sup 
  vertical-align: super !important
sub 
  vertical-align sub !important


.footer-menu
  d: flex
  jc: space-around
  pa: 20px 0
  +media(from('xl'))
    jc: space-between
    pa: 0
  &__ 
    &el 
      +media(from('xl'))
        ml: 10px