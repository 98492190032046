$slider-height = 22px
$slider-width = 100%
$rail-height = 6px
$knob-size = 22px
$rail-color = #FBE2A9
$rail-fill-color = #F0730D
$knob-color = #FBE2A9
$knob-border = 4px solid #F0730D
//$knob-shadow = 1px 1px rgba(0, 0, 0, 0.2)

.range-slider
  display: block
  //padding: 0 ($knob-size / 2)
  height: $slider-height
  width: $slider-width

  &__
    &inner
      display: inline-block
      position: relative
      height: 100%
      width: 100%

    &rail
    &fill
      display: block
      position: absolute
      top: 50%
      left: 0
      height: $rail-height
      border-radius: $rail-height / 2
      transform: translateY(-50%)

    &rail
      width: 100%
      background-color: $rail-color

    &fill
      background-color: $rail-fill-color

    &knob
      display: block
      position: absolute
      top: 50%
      left: 0
      box-sizing: border-box
      height: $knob-size
      width: $knob-size
      border: $knob-border
      border-radius: 50%
      background-color: $knob-color
      // box-shadow: $knob-shadow
      transform: translate(-50%, -50%)
      cursor: pointer

    &hidden
      display: none
