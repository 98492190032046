.certificates_list 
  background #F9F9F9
  box-shadow 0px 4px 30px rgba(238, 115, 37, 0.16)
  br-radius 12px
  pa 20px 
  mt 20px
  mb: 20px 
  +media(from('md'))
    mt 40px 
    pa 40px
    mb: 40px
  +media(from('xl'))
    mb: 60px

.certificate-view
  pos: relative
  br-radius: 10px 
  overflow: hidden
  cursor: pointer
  h: 300px 
  +media(from('md'))
    h: 300px
  +media(from('xl'))
    mt: 0
    h: 275px
    &:hover 
      .certificate-view
        &__zoom
          w: 100%
          h: 100%
          transition: 1s
          br-radius: 0
          bg: rgba(#EE7325, 0.81)
  &:before 
    content: ''
    w: 100%
    d: block 
    h: 100%
    pos: absolute 
    top: 0 
    left: 0
    bg: rgba(#EE7325, 0.46)
  &__
    &img
      d: block
      w: 100% 
      h: 100%
      object-fit: contain 
      object-position: center
    &zoom
      d: flex 
      ai: center 
      jc: center 
      br-radius: 50%
      transition: 1s
      bg: $c.orange
      pos: absolute 
      top: 50% 
      left: 50% 
      transform: translate(-50%, -50%)
      z-index: 2
      w: 50px 
      h: 50px
      // +media(from('xl'))
    &icon
      color: $c.white 
      fs: 20px
      pos: absolute 
      top: 50% 
      left: 50% 
      transform: translate(-50%, -50%)
      z-index: 3