.modal-wrapper
  pos fixed
  top 0
  left 0
  h 100%
  w 100%
  z-index 999
  overflow auto
  overflow-x hidden
  overflow-y auto
  align-items flex-start
  justify-content center

  &__
    &bg
      pos fixed
      top 0
      left 0
      h 100%
      w 100%
      d block
      z-index 0
      bg-color rgba(#FECA54 0.6)

    &window
      pos relative
      z-index 11
      w auto
      d block
      overflow hidden
      margin-top 115px

  &.is-
    &opened
      d block
      d flex

  &--
    &transition
      &-enter-active, &-leave-active
        transition opacity .5s ease-in

      &-enter, &-leave-to
        opacity 0

        & .modal
          transform translateY(-20px)
          opacity 0


      &-enter-active
        & .modal
          transition all .3s ease-in

      &-leave-active
        & .modal
          transition all .5s ease-out

mv = 425px
o = #F0730D
o2 = #F11515
.modal
  $offset = 1rem
  $hoffset = ($offset / 2)

  pos relative
  min-w 320px

  bg-color #fbe2a9
  br-radius $hoffset
  box-shadow 0 0 7px 1px rgba(0,0,0,0.1)
  &.is-trans 
    bg-color transparent
    box-shadow 0 0 0px 0px rgba(0,0,0,.0)

  &__
    &close
      pos absolute
      top $offset
      right $offset
      h $offset

      fs 2rem
      fh $offset
      fw bold
      color o
      &:hover
        color o2

    &inner
      pa 40px 40px 10px 40px
  &__footer
    text-align center
  &__title
    display block
    max-width mv
    font-size 26px
    color o
    text-align center
    margin-bottom 10px
    font-family SansRoundedC
    line-height 1.2
    margin-left auto
    margin-right auto
    white-space normal !important
    &--2
      font-size 26px
    &--3
      font-size 20px
    &--4
      font-size 36px
  &__desc
    color #000
    font-size 18px
    max-width mv
    text-align center
    margin-bottom 12px
    margin-left auto
    margin-right auto
    &--2
      font-size 12px
      margin-bottom 20px
  &__link
    margin-bottom 17px
    &.is-noactive
    &:disabled
      opacity 0.5
      cursor default !important
  &__wrap-icon
    position relative
    padding-top 100px
  &__icon-top
    display block
    width 81px
    height 81px
    overflow hidden
    position absolute
    top 0px
    left 50%
    transform translateX(-50%)
  &__part
    width 100%
    padding-top 25px
  &__link2
    width 150px
    text-align center
  &__social
    display block
    text-align center
    padding-bottom 5px
  &__soc
    display inline-block
    width 35px
    margin 0 5px
  &__rules
    display block
    padding 14px 0
    text-align center
    pos relative
  &__check
    // display none
    w 1px
    h 1px
    opacity 0
  &__label
    display block
    font-size 12px
    cursor pointer
  &__label:before
    content ''
    display inline-block
    width 13px
    height 13px
    border-radius 2px
    bg-color o
    vertical-align middle
    margin-top -3px
    margin-right 8px
  &__check:checked + &__label:before
    content url(./../images/check.png)
    text-align center
    line-height 11px
  &__link-rules
    display block
    text-align center
    text-decoration underline
    font-size 12px
    color #000
  &__link-rules:hover
    text-decoration none
  &__step
    display block
    padding 10px 0 17px 0
  &__product
    display block
    font-size 12px
    text-align center
    color #000
    margin-top 10px
  &__picture
    display block
    width 147px
    height 106px
    text-align center
    position relative
    margin 12px auto
  &__icon
    display block
    width 50px
    height 50px
    position absolute
    right 0
    bottom 0
    bg-repeat no-repeat
    bg-size cover
    bg-position center center
    &--1
      bg-image url(./../images/m3.png)
    &--2
      bg-image url(./../images/m1.png)
    &--3
      bg-image url(./../images/m2.png)
  &__slider
    width 100%
    max-w 425px
    position relative
    padding 0 35px
  &__share-part
    text-align left
    padding-top 20px
  &__share
    width 255px
    float right
    text-align right
    padding-top 5px
    &--span
      color o
      font-size 16px
      padding-right 5px
      vertical-align middle
      font-family SansRoundedC
  &__block-table
    width 440px
  &__thead
    display flex
    align-items center
    display -webkit-flex
    -webkit-align-items center
    width 100%
    background-color o
    border-top-left-radius 10px
    border-top-right-radius 10px
  &__thead &__td
    text-align center
    color #fff
    vertical-align middle
  &__td
    width 25%
    float left
    font-size 16px
    color #000
    padding 5px
    vertical-align middle
    &--product
      width 100%
      height 59px
      overflow hidden
  &__ok-icon
    margin 10px 0
  &__qwestion
    display block
    width 100%
    padding-bottom 20px
    &:last-child
      padding-bottom 30px
  &__q-part
    width 100%
    text-align center
  &__q-block
    display inline-block
    vertical-align top
    width 100px
    padding 0 4px
    position relative
    cursor pointer
    &.is-active:after
      content ''
      width 21px
      height 21px
      bg-repeat no-repeat
      bg-size cover
      bg-position center center
      bg-image url(./../images/active-icon.png)
      position absolute
      top 22px
      right 30px
  &__screen
    width 433px
    margin 20px auto 0
  &__product-value
    width 25%
    float left
    padding 8px 0
  &__product-desc
    width 75%
    display table
  &__product-unit
    width 33.33%
    float left
    padding 8px 0
    &:nth-child(2),
    &:nth-child(3)
      text-align center
    &:first-child,
    &:nth-child(3)
      bg-color #FDDA89
  &__product-name
    padding 10px 18px
    font-size 16px
    line-height 18px
  &__tbody-wrap
    width 100%
  &__tbody:after
    content ''
    display block
    width 92%
    height 1px
    bg-color o
    margin 0 auto
  &__tbody:last-child:after
    content ''
    display none
  &__data
    border-left 1px solid #9D6846
    border-right 1px solid #9D6846
    border-bottom 1px solid #9D6846
    border-bottom-left-radius 10px
    border-bottom-right-radius 10px
    overflow hidden
  &__enter
    text-align center
  &__facebook
    display inline-block
    padding 3.5px 25px
    bg-color #3B5998
    margin-bottom 6px
    color #fff
    font-size 14px
    border-radius 12px
    font-weight 700
    &--icon:before
      content url(./../images/facebook.png)
      display inline-block
      vertical-align middle
      padding-right 17px
      height 33px
    &:hover
      opacity 0.8
  &__facebook span
    vertical-align middle
  &__or
    display block
    text-align center
    font-size 16px
    margin-bottom 6px
  &__input
    display inline-block
    width 262px
    bg-color #fff
    border-radius 12px
    border 1px solid #ECE6D8
    color #666
    padding 11px 25px
    font-size 16px
  &__input::-webkit-input-placeholder
      color #B8B8B8
      font-size 16px
  &__input::-moz-placeholder
      color #B8B8B8
      font-size 16px
  &__input:-moz-placeholder
      color #B8B8B8
      font-size 16px
  &__input:-ms-input-placeholder
      color #B8B8B8
      font-size 16px
  &__submit
    width auto !important
    display inline-block !important
    padding-left 35px !important
    padding-right 35px !important
    margin-top 14px !important
    margin-right 0 !important
  &__enter &__label
    display inline-block
  &__enter &__check
    display inline block
    position relative
    right -18px


.modal__unit .owl-wrap
  padding-bottom 20px
  border-bottom 1px solid o
  margin-bottom 20px
.modal__unit:last-child .owl-wrap
  border-bottom 1px solid transparent
  margin-bottom 0
.modal__unit .modal__slider
  padding 0 10px
.modal__slider .half__nav--prev
  left -20px
.modal__slider .half__nav--next
  right -20px

.modal__content .slides.calendar__slider
  width 335px
.modal__unit
  margin-bottom 25px
.modal__tbody .modal__product-unit
  float none
  height 100%
  display table-cell
  vertical-align top
  text-align left
  padding-left 2px
.modal__tbody .modal__product-desc .modal__product-unit:last-child
  text-align center
.modal__tbody .modal__product-unit img
  float left
  margin-right 8px
  max-width 40px
  max-height 35px
.modal__tbody .modal__product-unit span
  display block
  margin-left 10px



@media(max-width 960px)
  .modal__link
    display inline-block !important
    width auto !important
  .modal-wrapper
    align-items flex-start
    padding-top 30px
    padding-bottom 15px


@media(max-width 720px)
  .modal__desc,
  .modal__title,
  .modal__step,
  .modal__link-rules
    max-width 240px
  .modal__share-part
    max-width 290px
  .modal__inner
    padding 25px 15px 15px 15px
  .modal__title
    font-size 18px
  .modal__share
    text-align center
  .modal__close
    font-size 40px
    top 7px
    right 7px
  .calendar__link.popup__link
    width 100%
  .modal__desc
    font-size 14px
  .modal__desc--2
    font-size 12px
  .modal__link2
    display inline-block !important
    width 120px !important
  .modal__link2:first-child
    margin-right 15px
  .modal__part
    padding-top 15px
  .modal__label
    max-width 215px
    margin 0 auto
  .modal__step
    text-align center
    margin 0 auto
  .modal__step-list
    display inline-block
    width auto
  .modal__step .numeric__item
    display inline-block
    float none
  .modal__step .numeric__link
    margin 0 auto 8px
  .modal__unit
    width 260px
    margin 0 auto
  .modal__title--4
    font-size 22px
  .modal__qwestion
    width 270px
    margin 0 auto
  .modal__q-part
    display flex
    flex-wrap nowrap
    justify-content center
  .modal__q-block
    padding 0 2px
  .modal__screen
    width 250px
  .modal__share--span
    display block
    font-size 10px
  .modal__link-rules,
  .modal__share-part
    margin-left auto
    margin-right auto
  .modal__share
    width 120px
  .modal__block-table
    width 260px
    margin 0 auto
  .modal__product-name
    padding 5px 0px 5px 5px
    font-size 10px
  .modal__td
    font-size 10px
  .modal__product-value,
  .modal__product-unit
    padding 5px 0
  .modal__product-unit img
    max-height 28px
  .modal__tbody:after
    width 96%
  .modal__soc
    width 25px
  .modal__share
    padding-top 0
  .modal__facebook,
  .modal__input
    font-size 14px
  .modal__input::-webkit-input-placeholder
      font-style italic
      font-size 14px
  .modal__input::-moz-placeholder
      font-style italic
      font-size 14px
  .modal__input:-moz-placeholder
      font-style italic
      font-size 14px
  .modal__input:-ms-input-placeholder
      font-style italic
      font-size 14px
  .modal__input
    width 243px
    padding-left 10px
    padding-right 10px
  .modal__submit
    margin-left 0 !important
  .modal__desc-last
    max-width 260px
  .modal__q-block
    width 74px
  .modal__q-block.is-active:after
    right 15px
  .half__drop-name
    font-size 10px !important
  .modal-wrapper__window
    margin-top 30px

@media(max-width 720px)
  .modal__content .slides.calendar__slider
    width 290px

@media(max-width 480px)
  .calendar__row:last-child .calendar__hover-half
    left -60% !important

body
  &.has-
    &modal
      overflow hidden
