@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.eot')
	src: url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), 
			 url('./../fonts/svgfont.woff') format('woff'),
			 url('./../fonts/svgfont.woff2') format('woff2'),
			 url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$svgicon-biscuit= "\EA01"
$svgicon-kasha= "\EA02"
$svgicon-pasta= "\EA03"
$svgicon-pure= "\EA04"
$svgicon-without_pricorm= "\EA05"
$svgicon-smile= "\EA06"
$svgicon-copy= "\EA07"
$svgicon-search= "\EA08"
$svgicon-zoom= "\EA09"
$svgicon-share= "\EA10"
$svgicon-facebook= "\EA11"
$svgicon-telegram= "\EA12"
$svgicon-viber= "\EA13"


$svgicon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.svgicon, [class^="svgicon-"], [class*=" svgicon-"]
	@extend $svgicon
	display: inline-block

.svgicon-biscuit:before
	content: $svgicon-biscuit
.svgicon-kasha:before
	content: $svgicon-kasha
.svgicon-pasta:before
	content: $svgicon-pasta
.svgicon-pure:before
	content: $svgicon-pure
.svgicon-without_pricorm:before
	content: $svgicon-without_pricorm
.svgicon-smile:before
	content: $svgicon-smile
.svgicon-copy:before
	content: $svgicon-copy
.svgicon-search:before
	content: $svgicon-search
.svgicon-zoom:before
	content: $svgicon-zoom
.svgicon-share:before
	content: $svgicon-share
.svgicon-facebook:before
	content: $svgicon-facebook
.svgicon-telegram:before
	content: $svgicon-telegram
.svgicon-viber:before
	content: $svgicon-viber
