
.tutorial-wrapper
    
    min-height 638px
    .wrapper
        user-select none
        position absolute
        top 0
        left 0
        width 100%
        height 100%
        bottom 0
        background rgba(0,0,0,.33) 
        z-index 10
    &__tutorial
        width 290px
        height 100%
        background-repeat no-repeat
        background-position center 557px
        background-size 100% auto
        
        top 0
        left 50%
        transform translateX(-50%)
        position absolute
        z-index 10
    &__mask
        width 100%
        height 100%
        left 0
        right 0
        position absolute
        z-index 11
        background-color rgba(0,0,0,.33)
    &__nav-buttons
        pointer-events none
        z-index 10
        width 290px
        position fixed
        top 0
        left 50%
        height 100%
        transform translateX(-50%)
        
    &__tips-wrapper
        width 290px
        position absolute
        left 50%
        height 100%
        transform translateX(-50%)
        
    &__img
        position absolute
        z-index 10
        &--prev
            pointer-events all
            cursor pointer
            position fixed
            bottom 20px
            left 0px
            z-index 11
        &--end
            pointer-events all
            cursor pointer
            position fixed
            bottom 20px
            right 0px
            z-index 11
        // transform translateY(-50%) translateX(-50%)
        &--0
            left 25px
            top 591px
            
        &--1
            left: 6px
            top: 11px;
        &--2
            top 501px
            left 0px
        &--3
            top 552px
            left 4px
        &--4
            top 568px
            left 47px
        &--5
            top 663px
            left -5px
        &--6
            top 647px
            left 0px
        &--7
            top: 647px;
            left: calc(50% - 145px);
        &--8
            top 768px
            left -6px
        &--9
            top 603px
            left 34px
        &--10
            top 838px
            left -4px
        &--11
            top 514px
            left 105px
        
            
@media(min-width 480px)
    .tutorial-wrapper
        &__img
            &--1
                left 57px
@media(min-width 720px)
    .tutorial-wrapper
        &__img
            &--1
                left 176px
@media(min-width 840px)
    .tutorial-wrapper
        margin-bottom 200px
        &__tutorial
            width 659px
            background-repeat no-repeat
            background-position center 563px
        &__tips-wrapper, &__nav-buttons
            width 659px
            
         &__img
            &--0
                left 74px
                top 615px
                
            &--1
                left: -3px
                top: 5px;
            &--2
                left 40px
                top 544px
            &--3
                left 82px
                top 566px
            &--4
                left -25px
                top 574px
            &--5
                left 186px
                top 851px
            &--6
                left 95px
                top 542px
            &--7
                left: 137px
                top: 590px;
            &--8
                left 0px
                top 982px
            &--9
                top 706px
                left 78px
            &--10
                left 54px
                top 1086px
            &--11
                left 423px
                top 524px
           

@media(min-width 960px)
    .tutorial-wrapper
        margin-bottom 290px
        &__tutorial
            background-repeat no-repeat
            background-position center 463px
        &__img
            &--0
                left 74px
                top 515px
                
            &--1
                left: 109px;
                top: 37px;
            &--2
                left 42px
                top 444px
            &--3
                left 81px
                top 466px
            &--4
                left -25px
                top 474px
            &--5
                left 186px
                top 751px
            &--6
                left 94px
                top 441px
            &--7
                left: 136px
                top: 491px;
            &--8
                left -1px
                top 882px
            &--9
                top 606px
                left 78px
            &--10
                left 54px
                top 9086px
            &--11
                left 423px
                top 424px
@media(min-width 1030px)

    .tutorial-wrapper
        margin-bottom 0    
        &__tutorial
            
            width 1000px
            background-repeat no-repeat
            background-position center 400px
            background-size 100% auto
        &__tips-wrapper, &__nav-buttons
            width 1000px
            
        &__img
            &--0
                left 66px
                top 441px
                
            &--1
                left: 199px
                top: 30px;
            &--2
                left 34px
                top 361px
            &--3
                left 307px
                top 440px
            &--4
                left 163px
                top 414px
            &--5
                left 471px
                top 682px
            &--6
                left 213px
                top 378px
            &--7
                left: 259px
                top: 417px;
            &--8
                left 434px
                top 531px
            &--9
                top 589px
                left 118px
            &--10
                left 96px
                top 800px
            &--11
                left 649px
                top 354px
@media(min-width 1200px)
    .tutorial-wrapper
        &__img
            &--1
                left: 321px
                top: 30px;
