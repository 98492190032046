.range-wrap 
  pos: relative 
  pt: 80px
  pb: 50px
  +media(from('xl'))
    pt: 40px
    pb: 25px
.custom-mark
  pos: absolute 
  top: -75px
  d: flex
  ai: center 
  cursor: pointer
  jc: center
  border: 2px solid #B6AAA3
  br-radius: 50%
  f-dir: column
  color: #B6AAA3
  width 32px 
  h: 32px 
  transition: 0.5s
  +media(from('md'))
    width 38px 
    h: 38px 
  +media(from('xl'))
    top: -55px
    width 32px 
    h: 32px 
  +media(from('xl'))
    &:hover
      br-color: $c.orange
      transition: 0.5s
  &.is-active
    br-color: $c.orange
    color: $c.orange
  &__
    &title
      ta: center 
      fw: 400
      ff SansRoundedC
      lh: 1
      fs: 11px
      +media(from('md'))
        fs: 12px
        letter-spacing: -1.4px

    &text
      ta: center 
      fw: 400
      ff SansRoundedC
      fs: 9px
      d: none 
      +media(from('xl'))
        d: block
        fs: 7px
        letter-spacing: -0.4px

.custom-dot
  bg-color: $c.orange
  border: 1px solid #FFFAF7
  w: 34px
  h: 34px
  d: flex
  ai: center 
  jc: center
  br-radius: 50%
  box-shadow: 0 0 10px $c.orange
  top: -9px 
  left -5px
  pos: absolute
  +media(from('xl'))
    width: 24px
    h: 24px
    top: -4px
  &:before 
    content: ''
    br-radius: 50%
    d: block 
    flex-shrink: 0
    w: 26px
    h: 26px
    bg-color: $c.orange
    border: 1px solid #FFFAF7

.vue-slider-rail
  bg: #FFFAF7
  border-radius: 16px;
  border: 2px solid #EE7325;
.custom-process
  h: 7px !important
  top: 2px !important
  bg: #EE7325;

