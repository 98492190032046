.poll-checkboxes
  +media(from('xl'))
    mt: 10px
  .poll-input
    mb: 20px  
    +media(from('xl'))
      mb: 20px
  &__
    &el
      d: flex
      pos: relative 
      cursor pointer
      mt: 22px
      +media(from('xl'))
        mt: 16px
      &--free
        +media(to('xl'))
          mt: 0 
    &label
      d: flex 
      cursor pointer
      ai: center 
      color: black
      fs: 14px 
      +media(from('xl'))
        fs: 14px

    &link
      color: inherit 
      transition: 0.5s
      fd: underline
      +media(from('xl'))
        &:hover 
          color: $c.orange
          transition: 0.5s
    &text 
      ta: left    
    &input
      cursor pointer
      opacity 0
      top 11px
      width 24px
      height 24px
      z-index 9
      position absolute
      cursor pointer
      +media(from('xl'))
        width: 16px;
        h: 16px
        top: 1px
      &:focus 
        + label:before 
          br: 2px solid $c.orange

      & + label:before
        content "\2713"

        display inline-block
        color: white
        text-align center
        vertical-align middle
        font-size 0
        background-color rgba(236, 214, 201, 0.2);
        transition font-size linear 0.3s
        border: 2px solid #B6AAA3;
        border-radius: 4px;
        line-height 24px
        margin 0px 16px 0 0px
        height 24px
        // font-weight 900
        flex-shrink 0
        width 24px
        +media(from('xl'))
          line-height 16px
          margin 0px 16px 0 0px
          height 16px
          line-height 16px
          // font-weight 900
          flex-shrink 0
          width 16px
      &:checked + label:before
        background-color: $c.orange
        transition font-size linear 0.3s
        font-size 15px
        br: 2px solid $c.orange



    