.multiselect
  pa 0 !important 
  border 2px solid transparent
  cursor pointer
  box-sizing border-box
  +media(from('xl'))
    min-h: 30px
  &--
    &disabled
      cursor not-allowed
  &__
    &single,
    &tags
      bg transparent !important
      pa 0
      ma 0
      color: $c.black
      fs 13px
      h auto
      +media(from('xl'))
        fs: 12px
      
    &tags
      br none
      +media(from('xl'))
        min-h: 30px
    &placeholder 
      pt 6px
    &single
      w 100%
      d flex
      ai center
      pl 10px
      h auto
      fw 500
      pt 9px
      lh: 1
      mt 9px
      color: $c.black
      fs 13px !important
      fw: 700
      @extend $input
      color: #B6AAA3 !important;
      +media(from('xl'))
        pl: 10px 
        mt: 3px
        fs 12px !important
    &select
      pa 0
      top 50%
      w 10px
      right 10px
      h 5px
      fw: 700
      transform: translateY(-50%);
      &:before
        br-color: $c.orange transparent transparent
        color: $c.orange
        top 50% !important
        transform translateY(-50%) 
        pos absolute
        mt 0
    &input 
      pt 9px
      color: $c.black !important
      bg transparent !important
      pl 17px 
      mb 0 !important
      +media(from('xl'))
        pl: 10px
        pt: 2px
    &tag
      mt 18px  
    &tag 
      ml 5px  
    &content-wrapper
      left 0
  
    &option
      fs 12px
      pa 12px 5px
      white-space: normal !important
      .bold
        fw: 900
        color: $c.grey--dark
        // text-shadow: 0px 1px 0px  $color-primary
      &--
        &selected
          bg: rgba($c.orange, 1)
          color: $c.black
          .bold
            color: $c.black
          &.multiselect
            &__option
              &--highlight
                bg: rgba($c.orange, 1)
        &active
          border-radius: 10px;
          z-index 999
          pos relative
          .multiselect
            &__select
              top 50%
              transform translateY(-50%) rotate(180deg)  
        &highlight
          bg: rgba($c.grey--dark, 0.6)
          color: $c.black !important
          .bold
            color: $c.black

  