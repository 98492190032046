.opener
    &__search
        padding 0 5px
    &--alphabet
        background-color transparent
        .opener
            &__item
                .product-preview
                    height 100%
                    padding-top 10px
                    &__title
                        display none


    &--forbidden
        mt 40px
        .opener
            &__list
                border-radius none
            &__item
                position relative
                border 1px solid #f17f15
                color #f11515
                font-size 9px
                background-color #fbe2a9
                height 50px
                vertical-align top
                &.is-active
                    &:after
                        content ''
                        position absolute
                        bottom -2px
                        z-index 1123
                        left 50%
                        transition .2s ease all
                        transform translateX(-50%)
                        br-b 12px solid #fbe2a9
                        br-r 12px solid transparent
                        br-l 12px solid transparent
                        br-t 12px solid transparent
                    &:before
                        content ''
                        position absolute
                        bottom 0px
                        z-index 1123
                        left 50%
                        transition .2s ease all
                        transform translateX(-50%)
                        br-b 12px solid #f0730d
                        br-r 12px solid transparent
                        br-l 12px solid transparent
                        br-t 12px solid transparent
                &--top
                    &.is-active
                        &:after
                            content ''
                            position absolute
                            top -2px
                            z-index 1123
                            left 50%
                            transition .2s ease all
                            transform translateX(-50%)
                            br-t 12px solid #fbe2a9
                            br-r 12px solid transparent
                            br-l 12px solid transparent
                            br-b 12px solid transparent
                        &:before
                            content ''
                            position absolute
                            top 0px
                            z-index 1123
                            left 50%
                            transition .2s ease all
                            transform translateX(-50%)
                            br-t 12px solid #f0730d
                            br-r 12px solid transparent
                            br-l 12px solid transparent
                            br-b 12px solid transparent
                .product-preview
                    // position absolute
                    // bottom 10px
                    // line-height 18px
                    // left 50%
                    // width 100%
                    // transform translateX(-50%)
                    // display inline-block
                    // vertical-align bottom
                    d flex
                    align-items center
                    justify-content center
                    h 100%
                    pt 3px

                    &__preview
                        margin-top 5px
                        max-width 100%
                    // &__title
                    //     margin-top 10px
            &__close
                color #f11515
                &:hover
                    background-color #f11515
            &__next, &__prev
                color #f11515
                &:hover
                    background-color #f11515
            &__showup
                pl 27px
                pr 27px
                border 1px solid #f17f15
    &__title
        color #f11515
        text-align center
        fs 22px
        mb 12px
        font-weight 100
        font-family SansRoundedC
    &__list

        position relative
        list-style none
    &__item
        cursor pointer
        display inline-block
        width 25%
        text-align center
        color #fff
        height 111px
        position relative
        vertical-align top

        &.is-active
            &:after
                z-index 3
                content ''
                position absolute
                bottom 0px
                left 50%
                transform translateX(-50%)
                br-b 10px solid #fbe2a9
                br-r 10px solid transparent
                br-l 10px solid transparent
                br-t 10px solid transparent

        &--top
            &.is-active
                &:after
                    z-index 9
                    content ''
                    position absolute
                    top 0px
                    left 50%
                    transform translateX(-50%)
                    br-t 10px solid #fbe2a9
                    br-r 10px solid transparent
                    br-l 10px solid transparent
                    br-b 10px solid transparent

        .product-preview
            &__title
                font-weight 100
                font-family SansRoundedC
            &__preview
                w 90%
                h 65px
                margin-left auto
                margin-right auto
                bg-repeat no-repeat
                bg-size contain
                bg-position center center

            &__
                &title
                    font-family SansRoundedC
                    line-height 1

                &preview
                    vertical-align middle

    &__showup
        pos relative
        bg-color #fbe2a9
        pa 28px 28px
    &__close
        color #f0730d
        fs 30px
        font-family PTSans-bold
        pos absolute
        right 0px
        top 5px
        line-height 30px
        text-align center
        height 30px
        width 30px
        transition .2s ease all
        &:hover
            color #fff

            background-color #f0730d
            border-radius 50%
    &__next, &__prev
        color #f0730d
        transition .2s ease all
        position absolute
        font-family PTSans-bold
        font-size 18px
        height 22px
        width 22px
        cursor pointer
        line-height 22px
        text-align center
        top 50%
        transform translateY(-50%)
        &:hover
            color #fff
            background-color #f0730d
            border-radius 50%

    &__next
        right 0px
        padding-left 4px
    &__prev
        padding-right 0px
        left 0px

@media(min-width 960px)
    .opener
        &__item
            vertical-align top
            width calc(100% / 7)


        &__list
            overflow hidden
            border-radius 7px
        &__showup
            padding 28px 40px
        &--alphabet
            .opener__item
                &:last-child
                    border-bottom-right-radius 7px
                .product-preview
                    display block
                    img
                        vertical-align middle
                        margin-top 30px

                    &__title
                        width 100%
                        letter-spacing 1px
                        // word-break break-all
                        word-wrap break-word
                        font-weight 100
                        display block
                        text-align center
                        font-size 16px
                        font-family SansRoundedC
                        line-height 16px
                        height 32px
                        overflow hidden
        &--forbidden
            .opener
                &__list
                    border-radius 7px
                    overflow hidden
                    position relative
                    &:before
                        position: absolute;
                        content: '';
                        bottom: 0px;
                        width: 10px;
                        background-color: transparent;
                        height: 10px;
                        z-index: 10;
                        border-bottom-left-radius: 7px;
                        border-left: 1px solid #F17F14;
                        border-bottom: 1px solid #f17f15;
                &__item
                    fs 16px
                    &:nth-child(1)
                        border-top-left-radius 7px
                    &:nth-child(7)
                        border-top-right-radius 7px
                    &:last-child
                        border-bottom-right-radius 7px
                &__showup
                    pl 70px
                    pr 70px
        &__close
            top 15px
            right 15px
        &__title
            font-size 36px
        &__next
            right 15px
        &__prev
            left 15px


.fade-enter-active, .fade-leave-active
    transition-property opacity
    transition-duration .25s


.fade-enter, .fade-leave-active
    opacity 0

@media(max-width 960px)
    .opener--alphabet .opener__item .product-preview
        padding 14px 0
    .opener__item
        height auto







