.accordion
  background: #F9F9F9;
  box-shadow: 0px 4px 30px rgba(238, 115, 37, 0.16);
  border-radius: 12px;
  overflow: hidden
  margin-top 10px
  +media(from('360'))
    margin 10px 3% 0 3%
  &--is-open
    .accordion__question
      z-index 1

  &__
    &question
      position relative
      bg-color: #F9F9F9;
      pa 10px 13px
      pr 50px
      transition background-color .3s ease
      +media(from('xl'))
        pa 25px 13px
        pr 50px
      &:hover
        cursor pointer
    &description
      overflow hidden
      transition: max-height .8s ease
      bg-color:  #F9F9F9;
      position relative
    &text
      font-family: SansRoundedC;
      font-style: normal;
      font-weight: normal;
      color: #EE1B23;
      pl: 60px
      fs: 14px
      lh: 1.5
      +mq-xl()
        line-height: 30px;
        font-size: 20px;
    &content
      pa 0px 13px 13px 13px
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #2B0000;
      opacity: 0.7;
      +media(from('xl'))
        pa 0px 40px 50px 75px
    &icon
      br-radius 50%
      background: #FFF2D8;
      font-size 20px
      display inline-flex
      justify-content center
      align-items center
      color #EE1B23
      width 40px
      height 40px
      position absolute
      left 13px
      top 50%
      transform translateY(-50%)
      transition: 0.5s
      &:before
        margin auto
      +mq-xl()
        &:hover 
          opacity: 0.7
          transition: 0.5s



.expert-page
  &__
    &title
      font-family: SansRoundedC;
      font-style: normal;
      font-weight: normal;
      text-align: center;
      color: #F9F9F9;
      lh: 1.3
      fs: 36px 
      pt: 20px
      pb: 20px
      +mq-md()
        font-size: 36px;
        pt: 40px 
        pb: 30px 
      +mq-xl()
        font-size: 60px;
        line-height: 72px;
        pt: 70px 
        pb: 45px 

.faqs
  mt: 50px
  mb: 50px
  +mq-xl()
    mb: 100px
  &__question-text
    margin: 0 auto

.expert-detail-card 
  max-w: 710px 
  ma: 0 auto
  d flex 
  ai center 
  jc center 
  f-dir column 
  +media(from('md'))
    f-dir row 
    ai center
  &__
    &title 
      fd none 
      ff SansRoundedC
      fs 16px
      lh 18px
      fw 400
      mt 8px
      color #EE1B23
      +media(to('lg'))
        ta: center
      +media(from('md'))
        fs 22px 
        lh 24px
        tr 0.5s
      +media(from('xl'))
        fs: 30px
        lh: 30px
       
    &text
      ff: 'Open Sans'
      fw: 600
      fs: 12px
      lh: 24px
      mt 17px
      color: #2B0000
      opacity: 0.7
      +media(from('xl'))
        fs: 14px
    &desc 
      font-family: Open Sans;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 21px;
      color: #2B0000;
      opacity: 0.8;
      +media(to('lg'))
        ta: center
    &img 
      d block 
      w 100% 
      h auto 
      h: 100%
      object-fit: cover
    &info
      d flex 
      w 100%
      f-dir column
      +media(to('sm'))
        max-width: calc(100% - 40px);
    &img-wrap 
      pos relative  
      w: 100%
      border-radius: 50%
      overflow: hidden
      fd: none
      w 200px 
      h 200px 
      flex-shrink 0
      d: block
      mb: 20px 
      +media(from('md'))
        mr 36px
        mb: 0 
        transition: 0.5s
      +media(from('xl'))
        &:hover 
          opacity: 0.7  
          transition: 0.5s
    &button
      width 300px 
      h: 38px 
      br-radius: 37px
      bg: $c.orange
      font-family: SansRoundedC;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 12px;
      fd: none 
      text-align: center;
      d: flex 
      ai: center 
      jc: center
      color: $c.white
      transition: 0.5s
      mt: 30px
      +media(to('sm'))
        width 100%;
      +mq-xl()
        width 320px 
        fs: 16px
        &:hover 
          transition: 0.5s
          opacity: 0.7


.faq-question
  text-align center
  font-family: Open Sans;
  display flex
  flex-direction: column
  background-color #FFFAF7
  border-radius 98px
  +media(to('sm'))
    margin: 0 auto;
    border-radius 40px
    width 100%
    text-align: left;
  +media(to('mxs'))
    width 280px
  &__description
    font-size 18px
    margin: 40px 0
    +media(to('sm'))
      fs 12px
      margin 12px 0 16px 0
  .faq-inner
    margin: 20px
    padding: 50px
    border: 5px solid #EE7325
    box-shadow: 0px 0px 0px 30px rgba(255, 242, 216, 1)
    border-radius 98px
    +media(to('sm'))
     margin: 0px;
     border: 2px solid #EE7325
     box-shadow: 0px 0px 0px 4px rgba(255, 242, 216, 1)
     padding 19px
     border-radius 40px
    .section__title
      padding-top 0
    h3
      color: red
      font-size: 40px
      font-weight bold
      +media(to('sm'))
        fs 20px
    input
      border-radius: 16px;
      border: 2px solid #B6AAA3;
      background: rgba(236, 214, 201, 0.2);
      width: 90%
      margin-bottom 20px
      padding: 15px
      font-size 16px
      &:focus
        border: 2px solid #EE7325;
      +media(to('sm'))
        max-height: 36px
        border-radius: 12px
        font-weight 700
        fs 12px
        max-width: 100%;
        width: 100%;
        border: 1px solid #B6AAA3;
        &:focus
          border: 1px solid #EE7325;
      &::placeholder
        font-size 16px
        font-weight: bold;
        font-family: Open Sans
        color: #B6AAA3;
    textarea
      border-radius: 16px;
      border: 2px solid #B6AAA3;
      background: rgba(236, 214, 201, 0.2);
      min-height: 300px
      padding: 30px
      font-size: 14px;
      resize: vertical;
      width 90%;
      max-width: 100%;
      &:focus
        border: 2px solid #EE7325;
      +media(to('sm'))
        border: 1px solid #B6AAA3;
        width 100%
        min-height: 115px
        padding 10px
        &:focus
         border: 1px solid #EE7325;
    .orange-error
      border: 2px solid red;
    .error-message
      color: red
      font-size 15px
      margin-left: 6%;
      margin-bottom: 6px;
      text-align: left;
    .agree
      margin 40px 0 40px 5%
      font-size 14px
      font-weight: 400;
      font-family: Open Sans;
      display: flex;
      text-align left
      line-height: 19px;
      width: 90%;
      color: #392F20;
      +media(to('sm'))
        margin 14px 0 26px 0
      &__text 
        +media(to('sm'))
          fs 10px
          max-width: 100%;
      .check
        height 15px
        width 15px
        margin-right 10px
        padding-bottom 30px
        &.error
          box-shadow: inset 0px 0px 2px 2px #ff0000;
        +media(to('sm'))
          min-width: 12px;
      .text
        display inline-block
        width 90%
        vertical-align: top;

    label
      text-align left
      display block
      color #B6AAA3
      margin-right: 5px
      margin-left: 6%
      margin-bottom: 6px
      +media(to('sm'))
        fs 10px
        margin-left unset
    button
      margin-top 50px
      width 300px 
      background: #EE7325;
      box-shadow: 0px 8px 0px #E65A05;
      border-radius: 16px;
      height: 69px;
      font-size: 26px;
      font-family: SansRoundedC;
      color: #fff
      +mq-xl()
        width 320px
        &:hover 
          transition: 0.5s
          opacity: 0.7
      +media(to('sm'))
        width 166px
        height 43px
        fs 14px
        box-shadow: unset
        display block
        margin 0 auto
.policy
  display: inline-block;
  width: max-content;
  line-height: 19px;
  +media(to('sm'))
    max-width: 100%;
  a&
    letter-spacing: 0em;
    text-align: left;
    color: #EE7325;
    text-decoration: underline;
    text-underline-offset: 4px;
#success 
  display: none
.bubble 
  padding 20px
  display flex
  justify-content center
  align-items center
  font-size 19px

