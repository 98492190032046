.table 
  w 100%
  min-width 320px  
  background-color #ee7325
  border-collapse collapse
  font-family 'HelveticaNeueCyr'
  font-weight 500  
  @media(min-width 720px)
    w 420px 
    ma 0 auto
  @media(min-width 960px)
    w 100%
  @media(min-width 1200px)
    w 1000px
  thead 
    w 320px 
    h 120px
    tr
      &:first-child
        border-bottom 1px solid #FFCD64
        @media(min-width 960px)
          border-bottom 0
        th 
          &:nth-child(-n+2)
            d none
            @media(min-width 960px)
              d table-cell
              ta left
          &:nth-child(1)
            @media(min-width 960px)
              padding 45px 36px
              border-right 5px solid #FFCD64
          &:nth-child(2)
            @media(min-width 960px)
              w 115px
              pa 0
              padding-left 8px              
              transform rotate(-90deg)
              border-right 2px solid #fff              
          &:nth-child(3)
            @media(min-width 960px)
              border-bottom 2px solid #fff
      &:last-child 
        d flex
        th 
          @media(min-width 960px)
            w 95px
            pa 15px 5px
          @media(min-width 1200px)
            w 115px 
            pa 15px
          &:not(:last-child)
            border-right 1px solid #FFCD64
            @media(min-width 960px)
              border-right 2px solid #fff
        @media(min-width 960px)
          d table-row
          
  tbody 
    background-color #fae1ac
    position relative
    w 100%
    td
      display flex
      align-items center
      justify-content center
      ta center 
      color #ed5d01
      fs 16px
      pa 10px 18px
      min-height 54px
      flex-basis 20%
      vertical-align middle
      & h4        
        fs 16px
        fw 500
        @media(min-width 480px)
          fs 18px
        @media(min-width 960px)
          ta left
      & a 
        color #ed5d01
      &:first-child 
        w 100%
        h 64px
        d flex 
        align-items center
        color #ed5d01
        font-size 16px
        position absolute
        top 27%
        left 0 
        transform translateY(-50%)
        background-color #fae1ac !important
        pa 0
        & h4 
          w 80%
          ta left
        @media(min-width 480px)
          h 84px
        @media(min-width 960px)
          position static 
          transform none
          background-color inherit
      &:nth-child(2)
        d none
        @media(min-width 960px)
          d table-cell
      &:nth-child(2n+1)
        background-color #ffcd64
        @media(min-width 960px)
          background-color #ffcd64 !important
      @media(min-width 480px)
        fs 18px
        h 85px
      @media(min-width 960px)
        d table-cell
        h 80px
        
    tr 
      d flex
      w 100%
      pt 64px
      position relative 
      background-color #fae1ac
      border-bottom 1px solid #ffcd64
      @media(min-width 480px)
        padding-top 84px
      @media(min-width 960px)
        d table-row
        pt 0
        br-bottom 0
        &:first-child 
          border-top 5px solid #FFCD64
        &:nth-child(2n+1)
          & td:first-child
            background-color #fae1ac !important    
        &:nth-child(6), &:nth-child(8)
          border-bottom 5px solid #FFCD64
        & td:first-child          
          w 313px
          border-right 5px solid #ffcd64
          pa 10px
          & h4 
            width calc(100% - 62px)
            pl 12px
        & td:nth-child(2)
          w 113px
        & td:nth-child(n+3)
          w 95px
      @media(min-width 1200px)
        & td:nth-child(n+3)
          w 115px     
           
  th
    color #fff
    pa 17px 0
    flex-basis 20%
    & h3, h4 
      fw 500
      fs 16px
      @media(min-width 480px)        
        fs 20px
    @media(min-width 480px)
      pa 26px 0
    @media(min-width 960px)
      pa 19px 0
  &__img 
    width 20%
    height 45px  
    d flex 
    justify-content center 
    align-items center  
    & img 
      max-h 100%
    @media(min-width 960px)
      w 62px
      h 57px
  
// images = "", url("../images/morkov.png"), url("../images/yabloko.png"), url("../images/manka.png"), url("../images/ovsyanka.png"),  url("../images/telyatina.png"),  url("../images/ryba.png"),  url("../images/tvorog.png"),  url("../images/kefir.png"),  url("../images/maslo.png"),  url("../images/maslo2.png"),  url("../images/zheltok.png"),  url("../images/hleb.png")

//   for i in (1)..(13)
//     .table>tbody>tr:nth-child({i}):after    
//       background-image images[i]

