.scroller
  position relative
  mb 30px 
  .app__title.bged
    z-index 1
    min-width 320px
    @media(min-width 480px)
      min-width 420px
  &__padder
    ma 0 auto
    z-index 123123
  &__heading
    ma 0 auto
    z-index 1231231231
  &__img
    d block
    h auto
    ma 0 auto
    max-w 100%
    bg-color #FFCD64
    @media(min-width 960px)
      max-width initial
      width: 100%
  &__title
    fs 20px 
    ta center 
    color #fff
    // mb 20px
    @media(min-width 960px)
      fs 40px
  &__text 
    max-width 414px
    fw 600
    ma 0 auto 20px
    & h2 
      color #fff
      ta center
      fs 20px
      @media(min-width 960px)
        fs 40px
    & p 
      color #000 !important            
      fw 600 !important
      @media(min-width 960px)
        fs 16px !important
    @media(min-width 960px)
      max-w initial
      fs 16px
    @media(min-width 1200px)
      max-width 1000px


.scroller-wrapper
  @media(min-width 1200px)
    max-w 1000px
    ma auto
  p
    margin-top 21px
    pa 0 11px
  p, ol li
    margin-left: auto
    margin-right: auto
    max-width: 414px
    font-size: 14px
    font-weight 300
    letter-spacing: 0.2px
    color: #e71802;
    @media(min-width 960px)
      max-width: initial
      font-size: 14px;
      font-weight: 300;
      line-height: 18px;
      letter-spacing: 0.14px;
      padding-left 0
  ol
    max-width: 414px
    margin 0 auto
    @media(min-width 960px)
      max-width: initial
  sup
    display inline-block
    mr 3px
  ol
    pl 10px
    mt 15px
    @media(min-width 960px)
      pl 15px
  &__header
    w 320px !important
    h 120px !important
    z-index 3
    pos relative
    @media(min-width 480px)
      w 420px !important
      h 171px !important
      ma auto
    @media(min-width 960px)
      w 900px !important
      h 136px !important
    @media(min-width 1200px)
      w 1000px !important



.article-body
  ff: 'Open Sans'
  fs: 14px
  color: rgba(43,0,0,.7)
  lh: 24px
  p 
    fw: 400
    fs: 14px
    color: rgba(43,0,0,.7)
  ol 
    mt 10px
    counter-reset: li

    li 
      fw 400
      lh 25px
      color  rgba(43,0,0,.7)
      d block
      counter-increment: li
      &:before 
        d inline-block
        content  counter(li)"."!important
        color: red
        pr 5px

  ul
    mt 10px
    // list-style-type square 
    li 
      fw 400
      lh 25px
      fs: 14px
      color: rgba(43,0,0,.7)
      d flex

      &:before 
        content "\F12C"
        color: red
        pr 5px
        fs: 15px
        display: inline-block;
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: inherit;
        text-rendering: auto;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
  a
    fd: none 
    border-bottom: 1px solid black
    transition 0.5s
    color: rgba(43,0,0,.7)
    fs: 14px
    +mq-xl()
      &:hover
        color: $c.red 
        br-color: $c.red 
        transition 0.5s
  