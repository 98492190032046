.input-group
  w 100%
  mb 20px
  
  &--no-pa
    mt 0 !important
  &__item
    pos relative
    &--flex
      d flex
      jc space-between
      .input-group
          mt 0 !important
    &--xl-66
      +media(from('xl'))
        w 66%      
  &--error
    .input-group__error
      d block
     
  &__input
    pos relative
    w 100%
    h 56px
    outline none
    br-bottom: 1px solid #BDBDBD
    // bg-color: $c.white
    pa 20px 20px 0px 10px !important
    tr 0.5s    
    color: $c.grey--dark
    &::-webkit-input-placeholder
      color #696969
      fs 13px
    &:focus 
      br-bottom-color: #BDBDBD 
      tr 0.5s
    &--error
      br-color #da251c !important
      pos relative
      tr 0.5s
      &::-webkit-input-placeholder
        color: #da251c 
      
    &--xl-66
      w 66%
    &--xl-33
      w 32%
      ml 1% !important
    &--with-ma
      mb 10px  
      pr 20px  

  &__error 
    fs 11px
    fw 700 
    color white
    bottom 35px
    left 20px
    width calc(100%-20px)
    // visibility: hidden;
    // width: 120px;
    background-color: RGBA(0,0,0,.4);
    // text-align: center;
    padding: 5px;
    tr 0.5s
    position: absolute;
    z-index: 1;
    box-shadow 0 6px 10px rgba(61,78,95,.3)
    &--top 
      bottom 0
    &::after 
      content: " ";
      position: absolute;
      top 100%; 
      left: 10%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      opacity 0.4
      border-color:  black transparent transparent transparent;
    d flex
    ai center
    f-dir row
    jc flex-start
    &:nth-child(2)
      mt 10px
  &__title
    color: $c.grey--dark
    fs 13px
    fw 700
    ta center
  &__text 
    ta center  
  &--add
    ta left
    .form__input + .form__input
      mt 15px
  &.button--center
   
    w 100%
    // +media(from('md'))
    //   w 210px
    +media(from('xl'))
      ma 0
      
  &--column
    w 100% !important
    d flex
    jc flex-end
    f-dir column
    +media(from('md'))
      f-dir row
  &__button
    w 100%
    bg: $c.trans
    fs 14px
    h 40px
  &__delete-btn
    color: $c.grey--dark
    pa 5px 
    // pos absolute 
    // right 5px 
    fd none 
    // top 10px
    fs 10px
    bg-color: $c.white
    w 30px 
    h 45px 
    d flex
    ai center 
    jc center
    cursor pointer
    ml 1%
    // br: 1px solid $c.grey--dark
    &:hover 
      color: #da251c 
      br-color: #da251c 
  &__count
    color: $c.grey--dark
    pa 5px 
    // pos absolute 
    // right 5px 
    fd none 
    // top 10px
    fs 10px
    bg-color: $c.white
    w 30px 
    h 45px 
    d flex
    ai center 
    jc center
    ma 0 1% 0 0 !important
  textarea
    
    fs 13px
    min-h 70px
    color: $c.grey--dark
    resize none
    overflow auto
    &::-webkit-input-placeholder
      color: $c.grey--dark
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder
    color: $c.grey--dark
  input[type='file']
    d none  
  input[type="date"]::-webkit-calendar-picker-indicator
    color transparent 
    w 100%
    /*
    textarea:-webkit-input-placeholder
      font-family 'Titillium Web', sans-serif
      fs 13px
    textarea:-moz-placeholder
      font-family 'Titillium Web', sans-serif
      fs 13px  */
  &__input:focus + label  

    fs 11px 
    tr 0.5s
    left 10px
    color: $c.orange
    transform translateY(-10px)
  &__textarea:focus + label  
  
    transform translateY(-10px)
    fs 11px 
    tr 0.5s
    left 10px
    color: $c.orange
  &__label
    
    color #57575a
    font-size 13px
    font-weight 700
    pos absolute
    left 10px
    tr 0.5s
    z-index 1
    pointer-events: none
    top 21px
    color #696969
    display: inline-block;
    width: calc(100% - 25px)
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    ta left
    &--static
      pos static
      w 100%
      d block
      ta left
  .is-active-label
    left 10px 
    fs 11px
    color: $c.orange
    tr 0.5s
    transform: translateY(-10px)  
  input[type='number']
    ma 0 
    pa 0
  @media screen and (-webkit-min-device-pixel-ratio:0)
    select,
    textarea,
    input
      font-size: 13px
  input
    cursor pointer     
    min-w calc(100% - 15px)