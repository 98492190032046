
.parallax-viewport
    position: relative;
    overflow: hidden;

.parallax-layer
    position: absolute;



  
 @keyframes eye
    0%
        transform rotate(0deg) scale(0.8)
    50%
        transform rotate(-180deg) scale(1.5)
    100%
        transform rotate(-360deg) scale(0.8)
@keyframes eye2
    0%
        transform rotate(0deg) scale(0.8)
    50%
        transform rotate(180deg) scale(1.5)
    100%
        transform rotate(360deg) scale(0.8)
@keyframes eye3
    0%
        transform cale(1)
    50%
        transform scale(1.5)
    100%
        transform scale(1)

  

.more
  position relative
  z-index 4
  margin-top -3px  
  display none
  width 100%
  padding-bottom 20px
  &__block
    width 100%
    margin 0 auto
    padding 30px
    bg-color: $c.orange
    border-radius 15px
    box-shadow 0 0 14px 1px rgba(0,0,0,0.3)
    +media(from('xl'))
      width 775px 
    &__text
      font-size 16px
      line-height 1
      color #000
.home
  opacity 1
  display table
  text-align center
  z-index 3
  display block
  width 100%
  min-height 400px
  position relative
  z-index 1
  overflow hidden
  +media(from('sm'))
    min-height 280px
  +media(from('md'))
    min-height 275px
  .container  
    display table-cell
    vertical-align bottom
    text-align center
    
  &__block 
    padding-left 0
    padding-right 0
    left 50%
    transform translateX(-50%)
    width 300px
    padding-bottom 7px

    display block
    position absolute
    color: white
    
    +media(from('md'))
      padding-left 105px
      padding-right 105px
    +media(from('xl'))
      bottom 0
      padding 0 149px 10px
      left 0
      text-align center
  &__child
    width 296px
    height 265px
    bg-position bottom center
    bg-size auto
    background-image url(./../images/child-start-mobile.png)
    display block
    position absolute 
    bg-repeat no-repeat
    margin 0 auto
    bottom 0
    left 50%
    pt calc(100vh - 200px)
    transform translateX(-50%)
    @media(max-device-width: 700px) and (orientation: landscape)
      pt calc(100vh - 40px)
    +media(from('sm'))
      width 290px
    +media(from('md'))
      width: 682px;
      pt calc(100vh - 85px)
      height: 516px;
      background-image url(./../images/Desktop_version.png)
    +media(from('xl'))
      display block
      pt 0
      position absolute
      bg-size cover
      bg-position top center
      bg-image url(/static/images/Desktop_version.png)
  &__layer
    width 0 
  &__more
    margin-top 10px 
  &__hover 
    display none
    width 131px
    text-align center
    position absolute
    left 50%
    transform translateX(-50%)
    bottom -18px
    z-index 7
    height 180px  
  &__link-hover 
    display block
    width 100%
    height 125px
    text-align center
    position absolute
    left 0
    bottom 0
    bg-repeat no-repeat
    bg-position bottom center
    background-size contain  
  &__link
    width 30px
    height 25px
    font-size 0  
    +media(from('xl'))
      display block
      width 50px
      height 50px
      font-size 45px
      color white
      bg-repeat no-repeat
      position relative
      text-align center
      &:hover 
        .home__hover 
          d block
    & img 
      height 25px
  &__name 
    margin-bottom 11px
    font-size 16px
    margin-top 8px
    font-family SansRoundedC
    font-weight 300
  &__list
    padding-left 15px
    height 45px
    d block 
    & img 
      max-width 100%
  &__item
    padding 0 12px
    display block
    float left
    list-style none
    line-height 1
    +media(from('xl'))
      padding 0 20px
  &__paralax 
    d none   
    +media(from('xl'))
      width 100%
      position absolute
      top 0
      left 0
      text-align center
      height 100vh
      & img 
        width 1158px
        height 520px
        top 20%
        left 20%
    & img 
      width 100%
  
  &__layer-img
    z-index -1
    animation none !important
    position absolute
    d none 
    +media(from('xl'))
      d block
    
    &--1
      left: 5%
      top: 438.34px;
      width: 73.04px;
      height: 73.04px;
    &--2
      width: 234.13px;
      height: 234.13px;
      left: 8%
      top: 83.5px;

    &--3
      width: 104.14px;
      height: 104.14px;
      left: 25%
      top: 166.23px;

    &--4
      width: 115px;
      height: 115px;
      left: 71%;
      top: 294px;

    &--5
      width: 73.04px;
      height: 73.04px;
      left: 92%;
      top: 175.71px;
    &--6
      width: 208.05px;
      height: 208.05px;
      left: 85%;
      top: 358.12px;

    &--7
      width: 95px;
      height: 95px;
      left 21%;
      top: 347px;

    &--8 
      top 140px
      left 19px

.start-container 
  position relative
  d flex 
  jc center
  ai center 
  +media(from('xl'))
    jc center
    w 100%
.start 
  display block
  width 100%
  bg-repeat repeat
  bg-position top center
  bg-size cover
  background-attachment fixed
  bg-image url(./../images/start.jpg)
  padding-bottom 0 !important
  position relative
  // &__block
  //   width 75%
    // margin 0 auto
  &__container 
    position relative
    d flex 
    ai center 

  

  .app
    &__title
      // font-size 17px
      // text-align left
      // +media(from('sm'))
      //   fs 30px
      // +media(from('md'))
      //   font-size 40px
      // +media(from('xl'))
      //   ta center
      //   fs 48px 
      //   lh 48px
      &.is-blocked
        pointer-events: none

    &__start
      &__title
        width 80%
        +media(from('sm'))
          w 85%
          width 60%
          font-size 12px
          margin 0 auto
    &__header 
      position absolute
      top 0
      left 0
      // background-color transparent
      // z-index 10      
.app
  &__header 
    z-index 6
    +mq-xl()        
      z-index: 4
.slider-wrapper
  &__dots
    top 224px !important
  

        
.menu 
  d flex 
  ai center 
  jc center
  f-dir column 
  pos absolute 
  color: $c.grey--light
  +media(from('xl'))
    &:hover 
      .menu
        &__icon
          tr 0.5s
          box-shadow: 0 0 0 10px $c.orange, 0 0 0 13px $c.grey--light, 0 0 0 20px $c.orange, 0 0 0 23px $c.grey--light;
          animation: effect1_alt 1s linear 0s infinite ;
        &__text 
          tr 0.5s
          text-shadow: 2px 3px 0px $c.orange
          bg-color: $c.orange
    &:active
      .menu
        &__icon
          box-shadow: 0 0 0 18px $c.orange, 0 0 0 21px $c.grey--light, 0 0 0 20px $c.orange, 0 0 0 23px $c.grey--light;
      
  &__icon   
    w 72px 
    h 72px 
    bg-repeat no-repeat 
    bg-position center 
    d block
    br: 2px solid $c.grey--light
    br-radius 100%
    +media(from('xl'))
      box-shadow: 0 0 0 0 $c.orange, 0 0 0 0 $c.grey--light, 0 0 0 0 $c.orange, 0 0 0 0 $c.grey--light;
      
  &--1 
    top 65px 
    +media(from('md'))
      left 25%
    +media(from('xl'))
      left 54px 
      bottom 74px 
      top initial
    .menu__icon
      bg-image url(/static/images/rocket_icon.png)
  &--2  
    right 0
    top 65px
    +media(from('md'))
      right 25%
    +media(from('xl'))
      left 174px 
      right initial 
      top initial 
      bottom 56px
    .menu__icon
      bg-image url(/static/images/table-icon.png)
  &--3
    top 20px
    left 50% 
    transform translateX(-50%)
    @media(max-device-width: 700px) and (orientation: landscape)
      top initial 
      bottom 5px
    +media(from('xl'))
      bottom 39px 
      top initial 
      left 294px 
      transform translateX(0%)


    .menu__icon
      bg-image url(/static/images/kub-icon.png)
  &--4
    left 0
    top 177px
    +media(from('md'))
      left 25%
    +media(from('xl'))
      top initial 
      left initial 
      right 194px 
      bottom 54px 
    .menu__icon
      bg-image url(/static/images/calendar-icon.png)
  &--5
    top 177px
    right 0
    +media(from('md'))
      right 25%
    +media(from('xl'))
      right 72px 
      top initial 
      bottom 86px
    .menu__icon
      bg-image url(/static/images/video-mob-icon.png)
      +media(from('xl'))
        bg-image url(/static/images/video-desc-cion.png)
  &__text
    font-size: 13px;
    line-height: 12px;
    text-align: center;
    color: #FFF;
    fw 700
    pt 4px
    tr 0.5s

    font-family: SansRoundedC;

@keyframes effect1_alt 
{
  from {
    box-shadow: 0 0 0 10px $c.orange, 0 0 0 13px $c.grey--light, 0 0 0 20px $c.orange, 0 0 0 23px $c.grey--light;
  }
  40%{
    box-shadow: 0 0 0 13px $c.orange, 0 0 0 16px $c.grey--light, 0 0 0 26px $c.orange, 0 0 0 29px $c.grey--light;
  }
  90%{
    box-shadow: 0 0 0 3px $c.orange, 0 0 0 6px $c.grey--light, 0 0 0 14px $c.orange, 0 0 0 17px $c.grey--light;
  }
  to {
    box-shadow: 0 0 0 10px $c.orange, 0 0 0 13px $c.grey--light, 0 0 0 20px $c.orange, 0 0 0 23px $c.grey--light;
  }
}