.search-form
  +mq-xl()
    d: flex
.search-input
  pos: absolute 
  left: 0 
  width 100%
  top: 63px
  z-index: 9
  &:before 
    content: "\EA08"
    color: $c.orange
    ff: "svgfont"
    pos: absolute 
    top: 50%
    left: 13px
    z-index: 10
    transform: translate(-50%, -50%)
    fs: 13px
  +media(from('xl'))
    w: 485px
    left: initial 
    right: initial
    top: initial
    transform: translateY(0)
    position relative
  &__
    &close
      color: $c.orange
      ff: "svgfont"
      pos: absolute 
      top: 50%
      right: 13px
      z-index: 10
      fw: 900
      fs: 20px
      transform: translate(-50%, -50%)
  .poll-input 
    mb: 0
    &__
      &input
        bg: $c.white !important
        h: 32px !important
        pl: 26px
        pr: 26px
        br: none !important
        padding-top: 12px
        padding-bottom: 12px
        fs: 11px
        lh: 27px

       
  &.appearance_circle
    .poll-input 
      &__
        &input
          br-radius: 22px
            
  &.appearance_halfcircle
    .poll-input 
      &__
        &input
          br-radius: 22px 22px 0 0 
  

.search-results 
  br: 1px solid var(--theme-text-4)
.list-search
  max-h: 300px
  overflow-y: scroll
  +media(from('xl'))
    max-h: 515px
  .img-wrap 
    pos: relative
    .label 
      top: 0 
      left: 0 
  &__
    &preview    
      font-family: Open Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #333333
      pa 9px 7px 
      +media(from('xl'))
        pa 9px 14px 

    &link
      pa: 13px 
      w: 100%
      d: block
      fd: none 
      &:hover,
      &:focus
        bg: var(--theme-accent-purple)

.seacrh-result-wrapper
  pos: absolute 
  w: 100%
  right: 0 
  top: 95px
  bg: $c.white
  z-index: 56
  br-radius: 0 0 15px 15px
  +media(from('md'))
    width: 100% 
    // left: 13px
  +media(from('xl'))
    w: 485px
    top: 66px
    right: 50px

.search-preview
  d: flex 
  ai: center 
  jc: flex-start
  fd: none
  pa: 10px 8px 
  border-top: 1px solid #EEEEEE;
  +media(from('xl'))
    pa: 20px 14px 
    transition: 0.5s
    &:hover 
      transition: 0.5s
      bg: rgba($c.orange, 0.3)
  &__
    &img-wrap 
      pos: relative 
      d: block 
      w: 80px 
      mr: 8px 
      br-radius: 15px
      overflow: hidden
      h: 55px 
      flex-shrink: 0
      +media(from('xl'))
        w: 130px
        h: 90px
        mr: 24px
      &--
        &product
          bg: #A6C2E3
        &mini
          w: 32px 
          h: 32px
          +media(from('xl'))
            w: 50px 
            h: 50px
    &img 
      d: block 
      w: 100%
      h: 100%
      object-fit: cover 
      object-position: center
      &--
        &product
          object-fit: contain
    &play-icon
      bg: $c.orange
      w 32px 
      h 32px 
      br-radius 100%
      d block
      pos absolute 
      top 50% 
      tr 0.5s
      left 50% 
      transform: translate(-50%, -50%)
      &:after 
        content ''
        pos absolute 
        top 50% 
        left 55% 
        transform: translate(-50%, -50%)
        width: 0;
        height: 0;
        border-top: 4px solid transparent;
        border-left: 8px solid white;
        border-bottom: 4px solid transparent;
    &title
      fw: 700
      fs: 14px 
      lh: 21px 
      color: #2B0000
      mb: 9px
      font-family: Open Sans;
    &text 
      color: #2B0000
      font-family: Open Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
