.form
    margin-bottom 27px
    .control
        padding 10px 5px 4px
        margin-top 15px
        width 100%
        br-b 1px solid #fff
        color #fff
        font-family SansRoundedC
        font-weight 100
        fs 16px
        bg url('./../images/search.png') no-repeat 98% center
        &::placeholder
            color #fff
            font-size 16px
            font-family SansRoundedC
        &::-webkit-input-placeholder
            color #fff
            font-size 16px
            font-family SansRoundedC
        &::-moz-placeholder
            color #fff
            font-size 16px
            font-family SansRoundedC
        &:-ms-input-placeholder
            color #fff
            font-size 16px
            font-family SansRoundedC
            

