.product-card
    &__img-wrapper
        height value
        float left
        mr 26px
        d block
        w 122px
        h auto
        text-align center
    &__preview
        width 100%
        height 100px
        background-size contain
        background-repeat no-repeat
        background-position center top
    &__elements-wrapper
        position initial
    &__elements
        width 25%
        margin 10px 4px 0
        margin 20px 1px 0
        max-w 25%
    &__main-content
        color #404040
        fs 14px
    &__title
        letter-spacing 1px
        color #f0730d
        fs 22px
        font-family SansRoundedC
        font-weight 100
        mb 27px
        +media(to('md'))
            margin-left: unset !important; 
            
    &__content, &__spoiler
        font-weight 600
        height 100%
        color #000000
        font-size 16px
        word-break break-word
    &__content
        mb 45px
    &__spoiler-toggler
        margin-top 15px
        margin-bottom 24px
        font-family PTSans-italic
        text-decoration underline
        font-size 12px
        color rgba(0, 0, 0, 0.302)
        cursor pointer

    &__controls
        position absolute
        width 82%
        right 0
        bottom 38px
        float none
        padding 0 23px
        &--pos-static
            d inline-flex
            justify-content space-between
            position static 
            w 200px !important 
            mb 10px
            pa 0 !important 
    &__calendar-link, &__nutricia-link
        cursor pointer
        width 100px
        color #f11515
        font-size 10px
        font-weight 700
        padding 4px 0px 4px 40px
        padding-top 6px
        height 38px
        display block
    &__calendar-link
        float left
        background transparent url('./../images/calendar.png') no-repeat 6% center
        &:hover
            border-radius 6px
            background #f11515 url('./../images/calendar--active.png') no-repeat 6% center
            color #fff
    &__nutricia-link
        float left 
        color #f11515
        background-size 30px 29px
        background transparent url('./../images/cart.png') no-repeat 6% center
        padding-left 50px
        &:hover
            color #fff
            border-radius 6px
            background #f11515 url('./../images/cart--active.png') no-repeat 6% center
            background-size 32px 29px
    &--forbidden
        .product-card
            &__title
                color #f11515

@media(min-width 480px)

    .product-card
        &__nutricia-link
            float right
        &__controls
            w 300px

@media(min-width 720px)

    .product-card
        &__content
            mb 65px
        &__top 
            d flex 
            justify-content space-between
            align-items center
            mb 30px
        &__title 
            mb 0
        &__controls            
            &--pos-static                
                mb 0

@media(min-width 960px)
    .product-card
        &__img-wrapper
            width 25%
            position relative
        &__preview
            height 300px
        &__elements
            ma 10px
        &__elements
            width 20%
            height auto
            margin 0 10px
        &__main-content
            width calc(75% - 26px)
            float right
        &__content
            mb 85px
        &__spoiler-toggler
            margin-bottom 74px
        &__controls
            padding 0
            width 300px
            float right
            right 40px
            bottom 35px
            &--pos-static
                w 250px !important
        &__calendar-link, &__nutricia-link
            font-size 12px
            padding-top 4px
@media(min-width 1199px)
    .product-card
        &__img-wrapper
            height 320px
        &__preview
            height 70%
        &__elements-wrapper
            position absolute
            bottom 0
            left 50%
            width 100%
            transform translateX(-50%)
        &__controls            
            &--pos-static
                w 300px !important 
                pr 15px !important


@media(max-width 720px)
    .product-card__spoiler-toggler
        position static 
        padding 0
        width 100%
    .product-card__spoiler-toggler
        margin-bottom 8px
    

