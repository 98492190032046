
.controls 
  // text-shadow 6px 10px 21px rgba(0, 1, 5, 0.6)
  // color white
  fw 500
  fs 15px
  w 49px 
  h 49px
  pos absolute 
  d flex 
  ai center 
  jc center
  top 50%
  color: $c.red
  z-index: 555
  // box-shadow 0 28px 28px 2px rgba(55, 75, 85, 0.08)
  transform: translateY(-50%)
  tr 0.5s 
  cursor pointer
  +media(from('xl'))
    &:hover 
      opacity 0.5
      tr 0.5s 

  &-left 
    left 0
    +media(from('sm'))
      left 0px 
    &--without-grid
      opacity 0.35
      +media(from('sm'))
        left -30px
      +media(from('xl'))
        left -60px
  &-right
    right 0
    +media(from('sm'))
      right 0px  
    &--without-grid
      opacity 0.35
      +media(from('sm'))
        right -30px
      +media(from('xl'))
        right -60px
  &--mini
    w 28px 
    h 28px

  &--table 
    top 0
    transform translateY(0)
  &--center 
    top 50% 
    color: $c.grey--dark 
    // bg: $c.white
    transform translateY(-50%)

.post-slider
  ma 20px auto
  pa 10px 20px
  overflow hidden
  pa 10px calc(50% - 150px)
  +media(from('xl'))
    pa 28px 56px
    br-radius 12px
    d flex 
    ai center 
    w 100%
    jc space-between
  .slick-list
    overflow: visible 
    width 100%