.expert-card
  background #F9F9F9
  box-shadow 0px 4px 30px rgba(238, 115, 37, 0.16)
  br-radius 12px
  pa 20px 
  mt 20px
  d flex 
  ai center 
  jc center 
  f-dir column 

  +media(from('md'))
    mt 40px 
    f-dir row 
    ai flex-start
    pa 40px
  &__
    &title 
      fd none 
      ff SansRoundedC
      fs 16px
      lh 18px
      fw 400
      mt 12px
      color #EE1B23

      +media(from('xl'))
        fs 22px 
        lh 24px
        tr 0.5s
        mt: 0
      &--
        &is-link    
          +media(from('xl'))
            &:hover 
              color: $c.orange 
              tr 0.5s
      & h2 
        fw: 400
    &text
      ff: 'Open Sans'
      fw: 600
      fs: 12px
      lh: 24px
      mt 6px
      color: #2B0000
      opacity: 0.7
      +media(from('xl'))
        fs: 14px
        mt: 24px

    &img 
      d block 
      w 100% 
      h auto 
    &info
      d flex 
      w 100%
      f-dir column
    &img-wrap 
      pos relative  
      w: 100%
      +media(from('md'))
        w 215px 
        flex-shrink 0
        mr 50px