@font-face
    font-family 'PTSans-bold'
    src url('./../fonts/PT_Sans-Web-Bold.ttf')
@font-face
    font-family 'PTSans-regular'
    src url('./../fonts/PT_Sans-Web-Regular.ttf')
@font-face
    font-family 'PTSans-italic'
    src url('./../fonts/PT_Sans-Web-Italic.ttf')
// @font-face
//     font-family 'SansRoundedC'
//     src url('./../fonts/SansRoundedC.ttf')

@font-face
  font-family 'SansRoundedC'
  src url('./../fonts/sansroundedc-webfont.eot')
  src url('./../fonts/sansroundedc-webfont.eot?#iefix') format('embedded-opentype'),
       url('./../fonts/sansroundedc-webfont.woff2') format('woff2'),
       url('./../fonts/sansroundedc-webfont.woff') format('woff'),
       url('./../fonts/sansroundedc-webfont.ttf') format('truetype'),
       url('./../fonts/sansroundedc-webfont.svg#sansroundedcregular') format('svg')
  font-weight normal
  font-style normal

@font-face {
    font-family: 'HelveticaNeueCyr';
    src: url('./../fonts/HelveticaNeueCyr-Medium.woff2') format('woff2'),
        url('./../fonts/HelveticaNeueCyr-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'RotondaC';
    src: url('./../fonts/rotondac.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'RotondaC';
    src: url('./../fonts/rotondac-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/OpenSans-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: url('./../fonts/OpenSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}


@font-face {
  font-family: 'icomoon';
  src:  url('./../fonts/icomoon.eot?lufbu8');
  src:  url('./../fonts/icomoon.eot?lufbu8#iefix') format('embedded-opentype'),
    url('./../fonts/icomoon.ttf?lufbu8') format('truetype'),
    url('./../fonts/icomoon.woff?lufbu8') format('woff'),
    url('./../fonts/icomoon.svg?lufbu8#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-right:before {
  content: "\e915";
}
.icon-left:before {
  content: "\e916";
}
.icon-close3:before {
  content: "\e900";
}



// @font-face
//   font-family: Material Design Icons
//   font-weight: 500;
//   font-display: swap;
//   src: url('./materialdesignicons-webfont.woff2'),
//       url('./materialdesignicons-webfont.woff'),
//       url('./materialdesignicons-webfont.ttf'),
//       url('./materialdesignicons-webfont.eot')
