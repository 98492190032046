// .socials-wrap
.header-menu
  bg: $c.orange
  pa: 12px 0
  br-radius: 22px
  overflow: hidden
  &__
    &link
      color: $c.white
      fd: none
      ff: SansRoundedC
      lh: 40px
      fs: 13px
      d: block
      pa: 0 30px
      +media(from('xl'))
        &:hover 
          bg: #E65A05


.header-toggler
  pos relative   
  h: 32px
  d flex
  ai center 
  jc center 
  bg-position center 
  fd none 
  bg-size contain
  bg-repeat no-repeat
  tr 0.5s

  &__label 
    w 32px 
    h 32px
    d flex
    ai center 
    flex-shrink 0
    jc center
    pos relative
    z-index 1
    cursor: pointer 
    fs: 20px
    br: 2px solid $c.pseudo_white
    br-radius: 22px
    transition: 0.5s
    mr: 8px
    +media(from('xl'))
      mr: 25px 
      &:hover
        opacity 0.7
        transition: 0.5s
  #menu 
    top 20px
    width 0
    // transition .2s ease
    z-index 1
    pointer-events none
    .mob-menu-inner
      line-height 0.5
      opacity 0
  #menu-toggle 
    &:checked 
      & + label 
        &:before
          tr 0.5s
          content "\F156"
        & + #menu 
          top 32px
          left 0
          pos absolute
          width 300px
          height auto
          // pa 10px
          // box-shadow 0 2px 5px rgba(0,0,0,0.26)
          // transition .1s linear .1s
          z-index 999
          br-radius: 22px
          overflow: hidden
          .mob-menu-inner
            pointer-events all
            line-height 2.3
            opacity 1
            z-index 2
            transition .2s linear .2s

  [type="checkbox"]:not(:checked), [type="checkbox"]:checked 
    display none


.circle-btn
  @extend .header-toggler__label
  color: $c.white
  mr: 0 
  fs: 14px
  ml: 8px
  +media(from('xl'))
    ml: 25px
    mr: 0 
  &.is-hidden
    +media(from('xl'))
      d: none


.social-links
  pos: sticky
  z-index: 100
  bottom: 30px 
  width 0
  right: 30px
  left: calc(100% - 30px)
  transform: translateX(-50%)
  d: flex 
  jc: center 
  +mq-xl()
    left: calc(100% - 55px)
    bottom: 55px
    right: 55px 
  &.is-
    &active 
      .new-social-btn
        &__
          &share
            br-radius: 0 0 22px 22px 
            background: $c.white
            color: rgba(#EE7325, 0.25)
            tr: 0.6s
            +mq-xl()
              &:hover
                opacity: 1
                color: $c.orange
  &__
    &link
      color: $c.orange
      pa: 10px
      flex-shrink: 0
      transition: 0.5s
      // pb: 8px
      +mq-xl()
        &:hover
          opacity: 0.5
          transition: 0.5s
    &share
      w: 33px 
      height 33px
      d: flex 
      ai: center 
      flex-shrink: 0
      tr: 0.6s
      jc: center
      cursor: pointer
      fs: 12px 
      color: $c.white
      background: #EE7325;
      border-radius: 22px;
      +mq-xl()
        &:hover
          opacity: 0.7
          transition: 0.5s

      &:after 
        content: ''
        background: #EE7325;
        opacity: 0.2;
        border-radius: 50%
        width 54px
        height 54px
        position: absolute 
        z-index: -1
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)      

.new-social-btn
  pos: sticky
  z-index: 100
  bottom: 90px 
  right: 30px
  width 0
  left: calc(100% - 30px)
  d: flex 
  jc: center 
  transform: translateX(-50%)
  mt: 30px
  +mq-xl()
    left: calc(100% - 55px)
    bottom: 120px
    right: 55px 
  &.is-
    &active 
      .new-social-btn
        &__
          &share
            br-radius: 0 0 22px 22px 
            background: $c.white
            color: rgba(#EE7325, 0.25)
            tr: 0.6s
            +mq-xl()
              &:hover
                opacity: 1
                color: $c.orange
  &__
    &share
      w: 33px 
      height 33px
      d: flex 
      ai: center 
      flex-shrink: 0
      tr: 0.6s
      jc: center
      cursor: pointer
      fs: 12px 
      color: $c.white
      background: #EE7325;
      border-radius: 22px;
      +mq-xl()
        &:hover
          opacity: 0.7
          transition: 0.5s

      &:after 
        content: ''
        background: #EE7325;
        opacity: 0.2;
        border-radius: 50%
        width 54px
        height 54px
        position: absolute 
        z-index: -1
        left: 50%
        top: 50%
        transform: translate(-50%, -50%)
    &others
      d: flex 
      f-dir: column
      bg: $c.white
      br-radius: 22px 22px 0 0 
      pos: absolute 
      d: flex 
      ai: center 
      jc: center
      bottom: 100%
      w: 33px 
    &link
      color: $c.orange
      pa: 10px
      transition: 0.5s
      // pb: 8px
      +mq-xl()
        &:hover
          opacity: 0.5
          transition: 0.5s


.slideup-enter-active
  transition-duration: 0.3s
  transition-timing-function: linear


.slideup-leave-active
  transition-duration: 0.3s
  transition-timing-function: linear


.slideup-enter-to, .slideup-leave
  max-height: 100px
  overflow: hidden


.slideup-enter, .slideup-leave-to
  overflow: hidden
  max-height: 0


.app.start
  .new-social-btn,
  .social-links
    pos: fixed 