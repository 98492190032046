.select
    vertical-align middle
    // height 28px
    border: 2px solid #F9F9F9;
    border-radius: 22px;
    font-weight 100
    line-height 22px
    font-size 14px
    pos relative
    // pos absolute
    width 53px
    ff SansRoundedC
    cursor pointer
    // top 0px
    // right  5px
    // position absolute
    color white
    background url('./../images/arrow0.svg') no-repeat 31px 45%
    background-size 12px 10px
    z-index 6
    pt 2px
    d flex 
    jc center
    h 32px  
    ai center
    +media(from('xl'))
        // pos absolute 
        // right 5px 
        // top 50% 
        w 90px 
        bg-position 50px 45%
        // transform translateY(-50%)
    &.is-hidden    
        +media(from('xl'))
            d: none
    &:hover
        opacity .8
    &--active
        height 47px
        br-radius 11px
        +media(from('xl'))
            h 60px
    &__wrapper
        width 53px
        height 32px
        padding-right 25px
        padding-left 8px
        color white
        d: flex 
        ai: center 
        jc: center
        +media(from('xl'))  
            w 100%
            ta center
    &__link
        cursor pointer
        w 100% 
        ta center
        ff SansRoundedC
        padding-right 15px
        position absolute
        +media(from('xl'))
            pr 30px
        &:nth-child(1)
            top 0px
            +media(from('xl'))
                top 5px
        &:nth-child(2)
            top 20px
            +media(from('xl'))
                top 25px
