.poll-products
  d: flex 
  jc: space-around 
  mt: 20px
  mb: 20px
  flex-wrap: wrap
  +media(from('xl'))
    jc: space-between 
    // mt: 40px
    // mb: 40px
    flex-wrap nowrap
  &__
    &input 
      opacity 0
      visibility: hidden 
      w: 0 
      h: 0 
      &:checked + .poll-products__label
        --color: $c.orange
      &:disabled + .poll-products__label
        --color: #B6AAA3
        opacity: 0.5
        cursor: not-allowed
    &el
      cursor pointer    
      w: 80px
      +media(from('xl'))
        w: auto
        mr: 17px 
      &:last-child
        mr: 0 
    &label
      cursor pointer    
      d: flex 
      f-dir: column
      ai: center  
      jc: center 
      --color: #B6AAA3
      transition: 0.5s
      +media(from('xl'))
        &:hover 
          --color: #e65a05
    &icon
      border: 2px solid var(--color)
      d flex 
      ai: center 
      jc: center 
      transition: 0.5s
      color: var(--color)
      br-radius: 50%
      fs: 37px 
      width 60px
      h 60px
      +media(from('xl'))
        fs: 38px 
        width 56px
        h 56px
    &text 
      font-family: Open Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 1.5
      letter-spacing: 0em
      text-align: center
      mt: 17px
      +media(from('xl'))
        fs: 11px




      