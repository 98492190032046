.poll-promo
  &__
    &title 
      font-family: Open Sans;
      font-style: normal
      font-weight: 400
      line-height: 1.5
      letter-spacing: 0em
      text-align: center
      fs: 14px
      fw: 700
      mt: 20px
      // +media(from('xl'))
      //   mt: 44px
      //   font-size: 24px
    &code 
      font-family: SansRoundedC;
      font-style: normal;
      font-weight: normal;
      line-height: 1.3;
      color: $c.orange
      ta: center 
      fs: 20px
      // +media(from('xl'))
      //   font-size: 48px;
    &wrap
      pos: relative
    &info  
      color: $c.orange
      ta: center
      mt: 20px 
      mb: 20px 
      // +media(from('xl'))
      //   fs: 18px 
      //   mt: 60px
      //   mb: 44px
    &btn
      ma: 16px auto 0 !important    
    &success
      pos: absolute 
      color: $c.orange
      pa: 10px 
      pos: fixed 
      top: 0 
      br: 2px solid $c.orange
      left: 50%
      transform: translateX(-50%)
      bg: white 
      fs: 12px

.promo-tip
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  d: flex
  ai: center 
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #B6AAA3;
  mb: 20px
  cursor: pointer
  jc: flex-end
  +media(from('xl'))
    pos: absolute 
    jc: flex-start
    top: 50%
    right 0
    mt: 0 
    transform: translateY(-50%)
    fs: 12px

  &__icon
    mr: 5px 



.slidedown-enter-active
  transition-duration: 0.3s
  transition-timing-function: linear


.slidedown-leave-active
  transition-duration: 0.3s
  transition-timing-function: linear


.slidedown-enter-to, .slidedown-leave
  max-height: 100px
  overflow: hidden


.slidedown-enter, .slidedown-leave-to
  overflow: hidden
  max-height: 0