.poll-tips
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  color: #B6AAA3;
  fs: 12px
  ta: left
  +media(from('xl'))
    mb: 10px
    // font-size: 18px;
  &--
    &mini
      color: $c.orange
      +media(from('mac'))
        font-size: 14px;
.poll-form-control
  &__
    &bottom
      ma: 10px auto 0 

    &inner
      +media(from('xl'))
        d: flex 
        jc: space-between
        ai: center 
        flex-wrap: wrap
      +media(from('mac'))
        d: static


.poll-milupa-promo
  ma: 0 auto
  +media(to('lg'))
    w: 100px

  +media(from('xl'))
    w: 200px
  &__
    &img
      d: block 
      w: 100%
      ma: 0 auto

  