/* ====================================================================================================================
 * GENERAL STYLES
 * ==================================================================================================================== */
$reg = PTSans-regular
$bold = PTSans-bold
$it = PTSans-italic

$transition = .2s ease all
*
    margin 0
    padding 0
    outline none
    -webkit-box-sizing border-box
    -moz-box-sizing border-box
    box-sizing border-box

html 
    font-family $reg, sans-serif
    background-color #F4F8F9   
    
.container-fluid
    width 100%
    
.container
    width 320px
    margin 0 auto
    min-width 320px
    



@media(min-width 1200px) 
    .container 
        width 1170px
@media(min-width 960px) 
    .container
        width 930px

@media(min-width 720px) 
    .container
        width 690px
        
@media(min-width 480px) 
    .container
        padding 0 15px
        width 450px

    

body
    margin 0
    background #ffffff
html 
    min-height 100%  
body 
    height 100%
div,article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,summary
    display block
a
    text-decoration none
a:active, a:hover
    outline 0
    
input, select, button, textarea
    background transparent
    border none
    padding 0 
    margin 0
    font-family: 'Montserrat', sans-serif
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder
    color #d8d8d8
    font-size 13px
input::-moz-placeholder, textarea::-moz-input-placeholder
    color #d8d8d8
    font-size 13px
input:-moz-placeholder, textarea:-moz-input-placeholder
    color #d8d8d8
    font-size 13px

input:-ms-input-placeholder, textarea:-ms-input-placeholder
    color #d8d8d8
    font-size 13px

select::-ms-expand 
    display none

img 
    border none
    vertical-align middle
a 
    text-decoration none
.clearfix:before,.clearfix:after 
    content " "
    display table
    width 0
    height 0
    visibility hidden
.clearfix:after 
    clear both

o = #F0730D
o2 = #F11515
hov = #e65a05 
bor-hov = #BE4D08
t = #F11515
pink = #FFAB8A
rose = #D13D4B
lim = #92D050
brow = #D69F6A
yel = #FFCE58
oran = #F0730D
bg = #FBE2A9
error = #F11515
